import {
  Button,
  ButtonProps
} from "@chakra-ui/react";
import React from 'react';
import { Link } from "react-router";

import { Icon } from "@equidefi/ui/icon";

export const V2Button: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      as={Link}
      leftIcon={<Icon.Edit2 size="1em" />}
      colorScheme="gray"
      color="gray.500"
      variant="ghost"
      size="sm"
      {...props}
    >
      V2
    </Button>
  );
};
