import { SectionMiniTitle } from "@/areas/Investments/components/Sections";
import { ButtonActionGroup } from "@/components/ButtonActionGroup";
import { usePokeInvestor } from "@/hooks/investments";
import { TInvestmentDTO } from "@/types/dto";
import {
  Button,
  Card,
  CardBody,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Chips } from "@equidefi/ui";
import moment from "moment";

interface InvestmentDetailsCardProps {
  investment: TInvestmentDTO;
}

export const InvestmentDetailsCard: React.FC<InvestmentDetailsCardProps> = ({
  investment,
}) => {
  if (!investment) return null;
  const toast = useToast();
  const showReminder = investment.status === "PENDING";

  const pokeInvestor = usePokeInvestor(investment.id, {
    onSuccess: () => {
      toast({
        status: "success",
        description: `Sent a reminder to ${investment.user?.email}`,
      });
    },
    onError: () => {
      toast({
        status: "error",
        description: "Something went wrong",
      });
    },
  });

  const handleSendReminder = async () => {
    await pokeInvestor.mutateAsync();
  };

  return (
    <Card>
      <CardBody>
        <VStack align="stretch" spacing={4}>
          <SectionMiniTitle>Investment Details</SectionMiniTitle>

          <VStack align="start" spacing={3} _empty={{ display: "none" }}>
            {investment.expedited && (
              <HStack>
                <Chips.Expedited
                  size="sm"
                  variant="expedited"
                  tooltipText="Expedited Investment"
                />
                <Text color="gray.700" fontSize="md" my="0">
                  Expedited Investment
                </Text>
              </HStack>
            )}

            {investment.is_reinvestment && (
              <HStack>
                <Chips.Returning
                  size="sm"
                  variant="info"
                  tooltipText="Returning Investor"
                />
                <Text color="gray.700" fontSize="md" my="0">
                  Returning Investor
                </Text>
              </HStack>
            )}

            {investment.investment_notes?.length > 0 && (
              <HStack>
                <Chips.Notes
                  size="sm"
                  variant="unknown"
                  tooltipText="Has Notes"
                />
                <Text color="gray.700" fontSize="md" my="0">
                  Has Notes
                </Text>
              </HStack>
            )}
          </VStack>

          <VStack align="stretch" spacing={0}>
            <Text textStyle="body2" color="gray.600" my="0">
              Started: {moment(investment.create_date).format("MMM Do, yyyy")}
            </Text>
            <Text textStyle="body2" color="gray.600" my="0">
              Last updated:{" "}
              {moment(investment.modify_date).format("MMM Do, yyyy")}
            </Text>
          </VStack>

          <ButtonActionGroup variant="ghost" offset={-4} gap={1}>
            {showReminder && (
              <Button
                onClick={handleSendReminder}
                isLoading={pokeInvestor.isLoading}
              >
                Send Reminder
              </Button>
            )}
            <Button colorScheme="red">Cancel Investment</Button>
          </ButtonActionGroup>
        </VStack>
      </CardBody>
    </Card>
  );
};
