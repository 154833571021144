import { Box, IconButton, Tooltip } from "@chakra-ui/react";
import { Paper } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

const Card = ({ title, children, editable, onClick, tooltip }) => {
  return (
    <Box mb={6} className="col">
      <Paper py={5} px={6}>
        <div className="row align-items-center">
          <div className="col">
            <div className="d-flex justify-content-between">
              <h6 className="text-uppercase text-muted">{title}</h6>
              {editable && (
                <IconButton
                  size="xs"
                  colorScheme="gray"
                  icon={<Icon.Edit size="1em" />}
                  aria-label="Edit Status"
                  onClick={onClick}
                />
              )}
              {tooltip && (
                <Tooltip placement="auto" label={tooltip}>
                  <Box as="span" color="gray">
                    <Icon.Info size="1em" aria-label={tooltip} />
                  </Box>
                </Tooltip>
              )}
            </div>
            <h2 className="mb-0">{children}</h2>
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default Card;
