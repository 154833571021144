import { useState } from "react";
import { Button, HStack, Text, useClipboard } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

const CopyableIcon = ({ text, iconSize = ".8em", iconColor = "gray" }) => {
  if (!text) return null;
  
  const [isHovered, setIsHovered] = useState(false);
  const { hasCopied, onCopy } = useClipboard(text);

  return (
    <HStack spacing={3} align="center">
      <Text
        fontSize="md"
        color="gray.700"
        flex="1"
        mb={0}
        isTruncated
      >
        {text}
      </Text>
      <Button
        size="sm"
        variant="ghost"
        onMouseEnter={() => !hasCopied && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          onCopy();
          setIsHovered(false);
          setTimeout(() => {
            setIsHovered(false);
          }, 2000);
        }}
        leftIcon={
          hasCopied ? 
            <Icon.Check size={iconSize} /> : 
            <Icon.Copy size={iconSize} />
        }
        color={hasCopied ? "green.500" : `${iconColor}.500`}
        transition="all 0.3s ease"
        width={isHovered || hasCopied ? "100px" : "40px"}
        justifyContent="flex-start"
      >
        {isHovered || hasCopied ? (hasCopied ? "Copied!" : "Copy") : null}
      </Button>
    </HStack>
  );
};

export default CopyableIcon;
