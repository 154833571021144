import VaultClient from "@equidefi/portals/clients/VaultClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useQuery } from "@tanstack/react-query";

/*
* @param {Object} params
 * @param {string} [params.search]
 * @param {string} [params.status]
 * @param {number} [params.page]
 * @param {import("@tanstack/react-query").UseQueryOptions} options
 */
export const useSearchVault = ({ search = undefined, status, page = 1 } = {}, options = {}) => {
  const vaultApi = useApi(VaultClient);

  return useQuery(
    ["vault", "search", { search, status, page }],
    () => vaultApi.search({ search, status, page }),
    {
      keepPreviousData: true,
      ...options,
    }
  );
};
