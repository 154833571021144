import { Center, Container, Spinner, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router";

import { useAuth } from "@equidefi/portals/hooks/useAuth";
import { useRegister } from "@equidefi/portals/hooks/useAuthentication";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { Heading } from "@equidefi/ui";

import InviteUserForm from "../../../components/users/InviteUserForm";
import { useVerifyInvite } from "../../../hooks/useInvitations";

const AcceptInvitation = () => {
  const { token: inviteToken } = useParams();
  const navigate = useNavigate();
  const { data = {}, isLoading } = useVerifyInvite(inviteToken);
  let { invitation, token: userToken } = data;
  const toast = useToast();
  const { setToken } = useAuth();
  const register = useRegister("issuer", inviteToken);

  useDocumentTitle(["Accept Invitation"], "issuer");

  const onSubmit = async (values) => {
    try {
      const accessToken = await register.mutateAsync({
        first_name: values.first_name,
        middle_name: values.middle_name === "" ? null : values.middle_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        title: values.title === "" ? null : values.title,
        company: values.company === "" ? null : values.company,
        entity_type: "INDIVIDUAL",
      });
      setToken(accessToken);
      onComplete();
    } catch (e) {
      console.error(e);
      toast({ status: "error", description: "User is already registered" });
    }
  };

  const onComplete = () => {
    navigate(`/offering`);
  };

  useEffect(() => {
    if (invitation?.user && userToken) {
      setToken(userToken);
      onComplete(invitation.user);
    }
  }, [invitation, userToken]);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  if (!invitation) {
    toast({ status: "warning", description: "Your invitation is invalid" });
    return <Navigate to="/" />;
  }

  return (
    <Container minW="full" py="1.5rem" px="2.25rem">
      <Heading fontWeight="bold" textStyle="h1">
        Accept Invitation
      </Heading>
      <InviteUserForm
        isLoading={isLoading}
        invitation={invitation}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default AcceptInvitation;
