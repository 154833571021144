
export const APPROVED = "Approved";
export const REJECTED = "Rejected";
export const SUBMITTED = "Submitted";
export const PENDING = "Pending";

export const ACCREDITATION_STATUSES = {
  PENDING,
  SUBMITTED,
  APPROVED,
  REJECTED,
}

export const ACCREDITATION_STATUS_LIST = [
  PENDING, SUBMITTED, APPROVED, REJECTED
]
