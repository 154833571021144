import { Button, ButtonProps, useClipboard } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import React, { PropsWithChildren } from "react";

type ShareUrlButtonProps = PropsWithChildren &
  ButtonProps & {
    content?: string;
    hasCopiedText?: string;
  };

export const ShareUrlButton: React.FC<ShareUrlButtonProps> = ({
  content,
  children,
  hasCopiedText = "Copied!",
  ...rest
}) => {
  const shareUrl = useClipboard(String(content));

  if (!content) return null;

  return (
    <Button
      leftIcon={<Icon.Clipboard size="1em" />}
      {...rest}
      onClick={shareUrl.onCopy}
    >
      {shareUrl.hasCopied ? hasCopiedText : children}
    </Button>
  );
};
