import React, { useMemo } from "react";

import {
  isAdmin,
  isIssuerEditor,
  isIssuerOwner,
  isOfferingEditor,
  isOfferingOwner,
} from "@/helpers";
import { TUserDTO } from "@/types/dto";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

type TCanEditProps = {
  role?: "owner" | "editor";
  issuerId?: string;
  offeringId?: string;
  children: React.ReactNode;
};

export const canUserEdit = (
  user: Pick<TUserDTO, "roles">,
  options: Omit<TCanEditProps, "children">
) => {
  if (!user) {
    return false;
  }

  if (isAdmin(user)) {
    return true;
  }

  const { issuerId, offeringId, role } = options;

  if (issuerId) {
    if (role === "owner" && isIssuerOwner(user, issuerId)) return true;
    if (role === "editor" && isIssuerEditor(user, issuerId)) return true;
    if (
      !role &&
      (isIssuerOwner(user, issuerId) || isIssuerEditor(user, issuerId))
    )
      return true;
  }

  if (offeringId) {
    if (role === "owner" && isOfferingOwner(user, offeringId)) return true;
    if (role === "editor" && isOfferingEditor(user, offeringId)) return true;
    if (
      !role &&
      (isOfferingOwner(user, offeringId) || isOfferingEditor(user, offeringId))
    )
      return true;
  }

  return false;
};

export const useCanEdit = (props: Omit<TCanEditProps, "children">) => {
  const { data: user }: { data: TUserDTO | unknown } = useCurrentUser();

  return useMemo(
    () => canUserEdit(user as TUserDTO, props),
    [props.role, props.issuerId, props.offeringId, user]
  );
};

export const CanEdit: React.FC<TCanEditProps> = ({
  role,
  issuerId,
  offeringId,
  children,
}) => {
  const canEdit = useCanEdit({ role, issuerId, offeringId });

  if (canEdit) return children;
  return null;
};
