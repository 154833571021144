import { Button, ButtonProps, Tooltip } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Icon } from "@equidefi/ui/icon";

type TCountersignButtonProps = ButtonProps & {
  investmentId?: string;
  isCountersignable?: boolean;
};

const CountersignButton: React.FC<TCountersignButtonProps> = ({
  isCountersignable = false,
  investmentId,
  onClick = () => {},
  ...props
}) => {
  const { data: me } = useCurrentUser();
  const { data, isFetchedAfterMount } = useInvestmentSignatures(investmentId, {
    refetchOnMount: "always",
  });

  const {
    can_not_countersign_reason,
    countersigner,
    is_countersigned,
    is_countersignable,
    investment_agreement: agreement,
  } = data ?? {};

  const countersignButtonShow = !!agreement;

  const isCountersigner = useMemo(
    () => me?.email === countersigner?.email,
    [countersigner?.email, me?.email]
  );

  const countersignButtonEnabled = useMemo(() => {
    return isCountersigner && isCountersignable && is_countersignable;
  }, [isCountersigner, isCountersignable, is_countersignable]);

  const tooltipLabel = !isCountersigner
    ? can_not_countersign_reason ?? "You are not the countersigner"
    : can_not_countersign_reason;

  if (!countersignButtonShow || !isFetchedAfterMount) return null;

  if (is_countersigned) {
    return (
      <Button rightIcon={<Icon.Check size="1em" />} isDisabled {...props}>
        Countersigned
      </Button>
    );
  }

  if (!countersignButtonEnabled) {
    return (
      <Tooltip hasArrow placement="top-start" label={tooltipLabel}>
        <Button isDisabled {...props}>
          Countersign
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button onClick={onClick} isDisabled={!countersignButtonEnabled} {...props}>
      Countersign
    </Button>
  );
};

export default CountersignButton;
