import React from "react";
import { Outlet } from "react-router";
import {
  Container,
  Grid,
  GridItem,
  VStack,
} from "@chakra-ui/react";
import { InvestmentHeaderSkeleton } from "./SkeletonParts";
import { InvestmentSidebarCardSkeletons } from "./InvestmentSidebarCardSkeletons";
import { InvestmentNavigation } from "@/areas/Investments/InvestmentAreaContainer";

export function InvestmentAreaContainerSkeleton(): React.JSX.Element {
  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch">
        <InvestmentHeaderSkeleton />
        <InvestmentNavigation />
        <Grid gap="4" templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={2}>
            <Outlet />
          </GridItem>

          <GridItem>
            <InvestmentSidebarCardSkeletons />
          </GridItem>
        </Grid>
      </VStack>
    </Container>
  );
} 