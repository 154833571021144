import { HeadingProps, IconButton, IconButtonProps } from "@chakra-ui/react";
import { Heading } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

type TSectionMiniTitle = HeadingProps;

export const SectionMiniTitle: React.FC<TSectionMiniTitle> = (props) => {
  return (
    <Heading
      as="h3"
      color="equidefi.darkGray"
      mb="0"
      // @ts-ignore
      textStyle="subtext"
      fontWeight="semibold"
      textTransform="uppercase"
      {...props}
    />
  );
};

type TSectionEditIconButton = Omit<IconButtonProps, 'aria-label'>

export const SectionEditIconButton: React.FC<TSectionEditIconButton> = (props) => {
  return (
    <IconButton
      variant="ghost"
      size="md"
      colorScheme="blue"
      mr={-2}
      mt={-2}
      {...props}
      aria-label="Edit"
    >
      <Icon.Edit size="1.2em" />
    </IconButton>
  );
};
