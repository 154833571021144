import { Button, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react";

import { useCancelInvestmentAgreement } from "@equidefi/portals/hooks/useSignatures";

import InvestmentVoidModal, {
  TVoidFormValues,
} from "@/areas/Investments/shared/InvestmentVoidModal";
import { TInvestmentAgreementDTO } from "@/types/dto";
import { extractErrorMessage } from "@equidefi/portals";

type TVoidAgreementButtonProps = ButtonProps & {
  agreement: TInvestmentAgreementDTO;
  onVoided?: () => void;
};

const VoidAgreementButton: React.FC<TVoidAgreementButtonProps> = ({
  agreement,
  onVoided = () => {},
  ...rest
}) => {
  const cancelAgreement = useCancelInvestmentAgreement();
  const voidModal = useDisclosure();

  const toast = useToast();

  const handleConfirmVoid = async ({ reason }: TVoidFormValues) => {
    try {
      await cancelAgreement.mutateAsync({
        investmentId: agreement.investment_id,
        investmentAgreementId: agreement.id,
        reason: reason,
      });
      voidModal.onClose();
      toast({
        status: "success",
        description: `The investment agreement has been voided.`,
      });
      onVoided();
    } catch (e: unknown) {
      console.error(e);
      toast({
        status: "error",
        description: extractErrorMessage(
          e,
          "Sorry, we could not void this agreement. Our team has been notified"
        ),
      });
    }
  };

  if (!agreement) {
    return null;
  }

  return (
    <>
      <Button
        isLoading={cancelAgreement.isLoading}
        isDisabled={agreement?.status === "COMPLETED"}
        onClick={voidModal.onOpen}
        colorScheme="red"
        {...rest}
      />

      <InvestmentVoidModal
        isOpen={voidModal.isOpen}
        onClose={voidModal.onToggle}
        isLoading={cancelAgreement.isLoading}
        onSubmit={handleConfirmVoid}
      />
    </>
  );
};

export default VoidAgreementButton;
