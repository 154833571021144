/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router";

import { Button } from "@chakra-ui/react";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";

import FloatingSearch from "../../components/floating-search";
import Loader from "../../components/spinner";
import Header from "../../layouts/header";

import { useBrokerList } from "../../hooks/useBrokers";
import useDebounce from "../../hooks/useDebounce";

const Brokers = (props) => {
  const navigate = useNavigate();
  const [inputSearchValue, setSearchVal] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);

  const { data: brokers, isLoading: spinner } = useBrokerList(searchVal, {
    refetchOnMount: true,
  });

  useDocumentTitle(["Brokers"]);

  return (
    <>
      <Header name="Brokers">
        <Button as={Link} to="/brokers/create">
          New Broker
        </Button>
      </Header>

      <div className="container-fluid">
        <FloatingSearch
          placeholder="Search by name, company, tracking code, CRD number..."
          id="search-brokers"
          label="Search Brokers"
          onChange={(e) => setSearchVal(e.target.value)}
        />

        <Loader show={spinner}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-hover table-nowrap card-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Tracking Code</th>
                    <th>CRD Number</th>
                  </tr>
                </thead>
                <tbody>
                  {brokers?.map((broker, index) => {
                    return (
                      <tr
                        key={broker.id}
                        onClick={() => navigate(`/brokers/${broker.id}/broker`)}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="align-baseline">
                          {broker.first_name} {broker.last_name}
                        </td>
                        <td className="align-baseline">{broker.company}</td>
                        <td className="align-baseline">{broker.code}</td>
                        <td className="align-baseline">{broker.crd_number}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Loader>
      </div>
    </>
  );
};

export default Brokers;
