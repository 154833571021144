import { useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import * as Yup from "yup";

import { extractErrorMessage } from "@equidefi/portals";
import { ExpandableInput, Input, Modal, Select } from "@equidefi/ui";

import { MESSAGE } from "@/constants/forms";
import { useInvestmentAddManualPayment } from "@/hooks/investments";

const options = [
  { label: "Wire", value: "wire" },
  { label: "Check", value: "check" },
];

const PaymentSchema = Yup.object().shape({
  amount: Yup.number()
    .required(MESSAGE.REQUIRED("amount"))
    .moreThan(0, "Must not be $0"),
  payment_type: Yup.string().required(MESSAGE.REQUIRED("payment type")),
  notes: Yup.string().required(MESSAGE.REQUIRED("notes")),
  reference_number: Yup.string().nullable(),
});

type TManualPaymentModalProps = {
  investmentId: string;
  isOpen?: boolean;
  initialAmount?: number;
  onHide?: () => void;
  onSubmit?: () => void;
};

type TFormValues = {
  amount: number;
  payment_type: string;
  reference_number: string;
  notes: string;
};

const ManualPaymentModal: React.FC<TManualPaymentModalProps> = ({
  investmentId,
  isOpen = false,
  initialAmount = 0,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const defaultValues = useMemo<TFormValues>(
    () => ({
      amount: initialAmount ?? 0,
      payment_type: "",
      reference_number: "",
      notes: "",
    }),
    [initialAmount]
  );
  const toast = useToast();
  const addPayment = useInvestmentAddManualPayment(investmentId, {
    onSuccess: () => {
      onHide();
      onSubmit();
      toast({
        status: "success",
        description: "Manual payment has been added",
      });
    },
    onError: (e) => {
      toast({
        status: "error",
        description: extractErrorMessage(e),
      });
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues,
    resolver: yupResolver(PaymentSchema),
  });

  const handleOnClose = () => {
    reset();
    onHide();
  };

  const handleManualPayment = async (data: TFormValues) =>
    addPayment.mutateAsync({ data });

  return (
    <Modal<TFormValues>
      title="Add Manual Payment"
      isOpen={isOpen}
      onClose={handleOnClose}
      onSubmit={handleSubmit(handleManualPayment)}
      size="sm"
      buttons={[
        {
          label: "Add Payment",
          type: "submit",
          isLoading: addPayment.isLoading,
        },
        {
          label: "Cancel",
          action: handleOnClose,
          variant: "outline",
        },
      ]}
    >
      <Controller
        control={control}
        name="payment_type"
        render={({ field: { onChange, value, ref } }) => (
          <Select
            name="payment_type"
            label="Payment Type"
            isRequired
            isInvalid={!!errors.payment_type}
            error={errors.payment_type?.message}
            inputRef={ref}
            classNamePrefix="addl-class"
            options={options}
            value={options.find((c) => c.value === value)}
            onChange={(val: { value: string; label: string }) => {
              onChange(val.value);
            }}
            controlProps={{ mt: 1, mb: 2 }}
          />
        )}
      />

      <Controller
        control={control}
        name="amount"
        render={({ field: { onChange, value } }) => (
          <Input
            label="Amount"
            name="amount"
            as={NumberFormat}
            isInvalid={!!errors.amount}
            error={errors.amount?.message}
            isRequired
            prefix="$"
            value={value}
            // @ts-ignore
            allowNegative={false}
            allowLeadingZeros={true}
            thousandSeparator={true}
            onValueChange={(val: {
              floatValue: number;
              value: string;
              formattedValue: "string";
            }) => {
              onChange(val.floatValue);
            }}
          />
        )}
      />

      <ExpandableInput
        label="Notes"
        isRequired
        isInvalid={!!errors.notes}
        error={errors.notes?.message}
        rows={3}
        value={watch("notes")}
        {...register("notes")}
      />

      <Input label="FedWire/Check Number" {...register("reference_number")} />
    </Modal>
  );
};

export default ManualPaymentModal;
