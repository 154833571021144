import {
  Button,
  Modal as CModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { Heading, Text } from "@equidefi/ui";

type TConfirmModalProps = Omit<ModalProps, "onClose" | "children"> & {
  title: string;
  isOpen: boolean;
  children?: ReactNode;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  confirmButtonColor?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmModal: React.FC<TConfirmModalProps> = ({
  title,
  isOpen = false,
  children,
  description,
  cancelText = "Cancel",
  confirmText = "Ok",
  confirmButtonColor,
  onConfirm,
  onCancel,
  ...rest
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <CModal
      isOpen={isOpen}
      onClose={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      motionPreset="slideInBottom"
      isCentered
      {...rest}
    >
      <ModalOverlay backdropFilter="blur(8px)" />

      <ModalContent p={5} maxWidth="600px" w="auto" borderRadius="13px">
        <ModalHeader p={0}>
          <Heading fontWeight="bold">{title}</Heading>
        </ModalHeader>

        <ModalBody p={0} mb={0}>
          <Text color="secondary" mb="8">
            {description}
          </Text>
          {children}
        </ModalBody>

        <ModalFooter p={0} justifyContent="start">
          <SimpleGrid
            columns={2}
            w="full"
            gap={3}
            flex="1 1 auto"
            justifyContent="space-between"
          >
            <Button
              onClick={onConfirm}
              aria-label={confirmText}
              flexGrow={1}
              colorScheme={confirmButtonColor ?? "purple"}
              variant="solid"
              size="md"
              _hover={{ filter: "brightness(90%)" }}
              _active={{ filter: "brightness(70%)" }}
              transition=".1s"
              px={0}
              mx="0"
              fontWeight="600"
            >
              {confirmText}
            </Button>
            <Button
              onClick={onCancel}
              aria-label={cancelText}
              flexGrow={1}
              colorScheme="purple"
              size="md"
              variant="outline"
              transition=".1s"
              px={0}
              mx="0 !important"
              fontWeight="600"
            >
              {cancelText}
            </Button>
          </SimpleGrid>
        </ModalFooter>
      </ModalContent>
    </CModal>
  );
};
