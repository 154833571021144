import React from "react";
import {
  VStack,
  Flex,
  HStack,
  CardBody,
  Card,
  Divider,
  Skeleton,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

export const InvestmentUserAccountSkeleton: React.FC = () => {
  return (
    <Card>
      <CardBody>
        <Flex justify="space-between" align="center">
          <Skeleton height="16px" width="100px" />
          <Skeleton height="40px" width="40px" borderRadius="lg" />
        </Flex>
        <Skeleton height="24px" width="200px" mt={2} mb={4} />
        <Flex align="center" gap={2} mb={2}>
          <Icon.Mail size="1.2em" />
          <Skeleton height="20px" width="250px" />
        </Flex>
        <Flex align="center" gap={2}>
          <Icon.Phone size="1.2em" />
          <Skeleton height="20px" width="200px" />
        </Flex>
      </CardBody>
    </Card>
  );
};

export const InvestmentEntitySkeleton: React.FC = () => {
  return (
    <Card>
      <CardBody>
        <Flex justify="space-between" align="center" mb={2}>
          <Skeleton height="16px" width="100px" />
          <Skeleton height="40px" width="40px" borderRadius="lg" />
        </Flex>
        <HStack align="flex-start" gap={4} mb={0}>
          <Skeleton boxSize="40px" borderRadius="lg" />
          <VStack align="flex-start" gap={0} mb={2} mt={0} spacing={2}>
            <Skeleton height="24px" width="200px" mb={1} />
            <Skeleton height="16px" width="120px" />
          </VStack>
        </HStack>
        <VStack align="flex-start" spacing={2} mb={4}>
          {[1, 2, 3].map((_, index) => (
            <Skeleton key={index} height="16px" width="180px" />
          ))}
        </VStack>

        <Skeleton height="16px" width="100px" mb={2} />
        <Divider my={4} />
        <VStack align="flex-start" spacing={2} mb={4}>
          <Skeleton height="20px" width="150px" />
          <Skeleton height="16px" width="200px" />
          <Skeleton height="16px" width="200px" />
        </VStack>
      </CardBody>
    </Card>
  );
};

export function InvestmentSidebarCardSkeletons(): React.JSX.Element {
  return (
    <VStack spacing={4} align="stretch" flex={1}>
      <InvestmentEntitySkeleton />
      <InvestmentUserAccountSkeleton />
    </VStack>
  );
} 