import { useVaultRecord } from "@/hooks/useAccreditation";
import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { UploadComponent } from "@equidefi/portals/components/accreditation/UploadComponent";
import { useUpdateVault } from "@equidefi/portals/hooks/useAccreditation";
import { ACCREDITATION_OPTIONS } from "@equidefi/shared/constants/accreditation";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import Select from "react-select";

const AccreditationUpload = ({ investment, onClose }) => {
  const toast = useToast();
  const updateVault = useUpdateVault(investment.id);
  const { data: vault } = useVaultRecord(investment.vault_id);
  const onSubmit = async () => {
    try {
      await updateVault.mutateAsync({
        accreditation_method: values.accreditation_method,
      });
      toast({
        status: "success",
        description: "Investment files were saved successfully",
      });
      onClose();
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        description: "Failed to submit your files",
      });
    }
  };

  const { handleSubmit, values, setFieldValue, handleChange } = useFormik({
    initialValues: {
      accreditation_method: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit,
  });

  const hasErrors = useMemo(() => {
    if (!values.accreditation_method) {
      return true;
    } else if (
      !Object.entries(values).find(
        ([field, value]) => field.includes("doc_") && !!value
      )
    ) {
      return true;
    } else if (!values.utility_bill) {
      return true;
    }
    return false;
  }, [values]);

  if (updateVault.isLoading) {
    return (
      <Center h="100vh">
        <Spinner label="Saving files to investment vault..." />
      </Center>
    );
  }

  return (
    <Modal
      isOpen
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader>Accreditation Upload Override</ModalHeader>
        <ModalBody>
          <Text>
            Select an accreditation and upload files on behalf of this investor
            into the encrypted vault.
          </Text>
          <form onSubmit={handleSubmit}>
            <Select
              autoFocus={false}
              placeholder={"Select an Accreditation Method"}
              onChange={(value) => {
                setFieldValue("accreditation_method", value.value);
              }}
              options={ACCREDITATION_OPTIONS}
              components={{
                IndicatorSeparator: null,
              }}
            />
            <Box my={4} p={4} borderWidth="1px" borderRadius="lg" bg="white">
              <UploadComponent
                title="Upload accreditation documents"
                investmentId={investment.id}
                id="documents_upload"
                handleChange={handleChange}
                noDocumentWarning="You must upload at least one verification document."
                vault={vault}
                vaultFields={[
                  "doc_1_file",
                  "doc_2_file",
                  "doc_3_file",
                  "doc_4_file",
                  "doc_5_file",
                  "doc_6_file",
                  "doc_7_file",
                  "doc_8_file",
                  "doc_9_file",
                  "doc_10_file",
                ]}
              />
            </Box>

            <Box my={4} p={4} borderWidth="1px" borderRadius="lg" bg="white">
              <UploadComponent
                title=" Upload proof of residence"
                investmentId={investment?.id}
                id="document_upload_utility"
                handleChange={handleChange}
                vault={vault}
                vaultFields={["utility_bill"]}
                noDocumentWarning="You must upload a proof of address document."
              />
            </Box>
            <Box mt={10} mb={50}>
              <Button
                className="btn btn-eq-primary"
                type="submit"
                isDisabled={hasErrors}
              >
                Save Files
              </Button>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AccreditationUpload;
