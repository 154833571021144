import
  {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
  } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import Select from "react-select";
import * as Yup from "yup";

import { MESSAGE } from "@/constants/forms";
import { useOfferingInvestorExport } from "@/hooks/useOfferings";

const ExportOptions = [
  {
    label: "All Investors",
    value: "ExportAllInvestors",
  },
  {
    label: "Available to close",
    value: "ExportAvailableToClose",
  },
  {
    label: "Funded Without KYC/Agreement/Accreditation",
    value: "ExportFundedNoAccreditation",
  },
  {
    label: "Not funded with Accreditation docs",
    value: "ExportNotFundedWithAccreditation",
  },
  {
    label: "Accreditation Rejected",
    value: "ExportAccreditationRejected",
  },
  {
    label: "Current Filter(s)",
    value: "ExportFilteredInvestors",
  },
  {
    label: "Stripe capture pending",
    value: "ExportStripeCapturePending",
  },
  {
    label: "Stripe capture succeeded",
    value: "ExportStripeCaptureSucceeded",
  },
];

const ExportInvestors = ({
  offeringId,
  show = false,
  filters = {},
  onSubmit = () => {},
  onHide = () => {},
}) => {
  const toast = useToast();
  const exportInvestors = useOfferingInvestorExport(offeringId);

  const handleSubmit = async (data) => {
    if (!offeringId) {
      toast({
        status: "error",
        description:
          "Offering ID is undefined, you cannot generate an offering without an ID",
      });
      return;
    }

    const exportType = data.exportType.value;
    const hasFilters = exportType === "ExportFilteredInvestors";

    try {
      await exportInvestors.mutateAsync({
        exportType: data.exportType.value,
        ...(hasFilters ? { filters } : {}),
      });
      onHide();
      toast({
        status: "success",
        description:
          "Your export file is being generated, the link to download will be sent to your email address.",
      });
      onSubmit(data);
    } catch (e) {
      toast({
        status: "error",
        description: "Sorry, this export could not be generated.",
      });
    }
  };

  return (
    <Modal size="lg" isOpen={show} onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <span className="fs-2">Offering Investor Exports</span>
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          enableReinitialize
          initialValues={{}}
          validationSchema={Yup.object().shape({
            exportType: Yup.object().required(MESSAGE.REQUIRED("exportType")),
          })}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ touched, errors, setFieldValue }) => (
            <Form>
              <ModalBody>
                <FormControl isInvalid={errors.status && touched.status}>
                  <FormLabel>Export Method</FormLabel>

                  <Select
                    autoFocus={false}
                    placeholder={"Select an Export"}
                    onChange={(value) => {
                      setFieldValue("exportType", value);
                    }}
                    options={ExportOptions}
                    components={{
                      IndicatorSeparator: null,
                    }}
                  />
                  <FormErrorMessage>{errors.exportType}</FormErrorMessage>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button size="sm" type="submit">
                  Generate Export CSV
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ExportInvestors;
