import { Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router";

import { Icon } from "@equidefi/ui/icon";

import {
  HeaderAvatar,
  HeaderCard,
  HeaderTitle,
} from "@/components/nav/HeaderCard";
import { TInvestmentDTO } from "@/types/dto";

interface InvestmentHeaderCardProps {
  investment: TInvestmentDTO;
}

export function InvestmentHeaderCard({
  investment,
}: InvestmentHeaderCardProps): React.JSX.Element {
  const { offering, issuer, vault } = investment || {};
  const { name: issuerName, photo_url: issuerPhotoUrl } = issuer || {};

  return (
    <HeaderCard>
      <Flex align="center" gap={3}>
        <HeaderAvatar url={issuerPhotoUrl} alt={`${issuerName} Logo`} />
        <Box>
          <Flex align="center" gap={2}>
            <Text
              as={Link}
              to={`/v2/issuers/${offering?.issuer_id}`}
              color="gray.600"
              fontSize="md"
              lineHeight="1"
            >
              {issuerName}
            </Text>
            <Icon.ChevronRight size="1em" />
            <Text
              as={Link}
              to={`/v2/offerings/${offering?.id}/investments`}
              color="gray.600"
              fontSize="md"
              lineHeight="1"
            >
              {offering?.name}
            </Text>
          </Flex>
          <HeaderTitle mb={0}>
            Investment by {investment.vault?.display_name ?? "N/A"}
          </HeaderTitle>
        </Box>
      </Flex>
    </HeaderCard>
  );
}
