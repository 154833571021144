import {
  SectionEditIconButton,
  SectionMiniTitle,
} from "@/areas/Investments/components/Sections";
import { ButtonActionGroup } from "@/components/ButtonActionGroup";
import {
  Box,
  Button,
  Card,
  Circle,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { EntityTypeIcon } from "@equidefi/portals/components/EntityTypeIcon";
import { capitalizeEveryWord } from "@equidefi/shared/helpers/string";
import { Icon } from "@equidefi/ui/icon";
import React from "react";
import { Link } from "react-router";

type ProfileStatus = "SUBMITTED" | "APPROVED" | "PENDING" | "REJECTED";

interface Address {
  street: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

interface ProfileCardProps {
  status: ProfileStatus;
  displayName?: string;
  entityType?: string;
  address?: Address;
  hasAddress?: boolean;
  onEdit?: () => void;
}

const STATUS_CONFIG: StatusConfig = {
  PENDING: {
    bg: "orange.50",
    badgeBg: "orange.300",
    text: "READY FOR VERIFICATION",
  },
  SUBMITTED: {
    bg: "orange.50",
    badgeBg: "orange.300",
    text: "READY FOR VERIFICATION",
  },
  APPROVED: {
    bg: "green.50",
    badgeBg: "green.500",
    text: "VERIFIED INVESTOR PROFILE",
  },
  REJECTED: {
    bg: "gray.50",
    badgeBg: "gray.600",
    text: "INVESTOR PROFILE NOT VERIFIED",
  },
};

const ProfileCard: React.FC<ProfileCardProps> = ({
  status,
  displayName = "N/A",
  entityType,
  address,
  hasAddress,
  onEdit,
}) => {
  const config = STATUS_CONFIG[status];

  return (
    <Card bg={config.bg} border="1px solid" borderColor={config.badgeBg} p={5}>
      <HStack justify="space-between" mb="4">
        <HStack
          bg={config.badgeBg}
          color="white"
          py={1}
          px={2.5}
          borderRadius="8px"
          top={4}
          left={4}
          spacing={2}
        >
          {status === "APPROVED" ? (
            <Icon.Shield size="1.2em" fontWeight="bold" />
          ) : (
            <Icon.ShieldOff size="1.2em" fontWeight="bold" />
          )}
          <SectionMiniTitle color="white" my={0.5} px={0}>
            {config.text}
          </SectionMiniTitle>
        </HStack>

        {status !== "APPROVED" && (
          <SectionEditIconButton mr="initial" mt="initial" onClick={onEdit} />
        )}
      </HStack>

      <HStack spacing={2} align="center" mb="3">
        <EntityTypeIcon type={entityType} size="2.3em" />

        <Box alignSelf="center" py={1}>
          <HStack spacing={4} align="center">
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="gray.900"
              lineHeight="1"
              my={1}
            >
              {displayName}
            </Text>
            {status === "APPROVED" && (
              <Circle size="20px" bg="green.500">
                <Icon.Check size="1em" color="white" />
              </Circle>
            )}
          </HStack>

          <Text
            color="gray.500"
            fontSize="md"
            mt={0}
            fontWeight="normal"
            mb="0"
          >
            {entityType ? capitalizeEveryWord(String(entityType)) : "N/A"}
          </Text>
        </Box>
      </HStack>

      {hasAddress && (
        <VStack align="start" spacing={0} mb={2}>
          <Text fontSize="md" color="gray.700" lineHeight="1" my={1}>
            {address.street}
          </Text>
          <Text fontSize="md" color="gray.700" lineHeight="1" my={1}>
            {address.city}
          </Text>
          <Text fontSize="md" color="gray.700" lineHeight="1" my={1}>
            {address.state}, {address.postal_code}, {address.country}
          </Text>
        </VStack>
      )}

      <ButtonActionGroup offset={-3} variant="ghost">
        {status !== "APPROVED" && (
          <Button
            colorScheme="blue"
            as={Link}
            to="#"
            fontSize="md"
            onClick={onEdit}
            mt={2}
          >
            Edit Profile
          </Button>
        )}
      </ButtonActionGroup>
    </Card>
  );
};

export default ProfileCard;
