/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import NumberFormat from "react-number-format";
import { Link, useNavigate, useParams } from "react-router";

import { useIssuer } from "@equidefi/portals/hooks/useIssuers";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";

import StatusBadge from "@/components/OfferingStatusBadge";
import PaginationRow from "@/components/PaginationRow";
import Spinner from "@/components/spinner";
import { isAdmin, isIssuerOwner, stockTypeName } from "@/helpers";
import { useIssuerOfferings } from "@/hooks/useOfferings";
import Header from "@/layouts/header";
import { useQueryParams } from "@equidefi/portals/hooks/useQueryParam";

const IssuerOfferings = (props) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const queryParams = useQueryParams({
    page: { defaultValue: 1, type: "number" },
  });

  const { data: me } = useCurrentUser();
  const { data: issuer } = useIssuer(id);
  const { data, isLoading } = useIssuerOfferings(id, queryParams.get("page"));
  const { offerings = [], pagination = {} } = data ?? {};

  useDocumentTitle(["Offerings", issuer?.name]);

  const goToOffering = async (offering) => {
    navigate(`/offering/${offering.id}/overview`);
  };

  return (
    <>
      <Header name="Offerings" title={issuer?.name}>
        {isAdmin(me) && (
          <Link to={`/issuer/${id}/edit`} className="btn btn-light me-2">
            Edit
          </Link>
        )}
        {(isAdmin(me) || isIssuerOwner(me, issuer?.id)) && (
          <Link to="/offering/create" className="btn btn-eq-primary">
            New Offering
          </Link>
        )}
      </Header>

      <div className="container-fluid">
        <div className="card">
          <Spinner show={isLoading}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th className="">Offering</th>
                    <th className="">Type</th>
                    <th className="">Launch Date</th>
                    <th className="">Amount</th>
                    <th className="">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {offerings?.map((offering, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => goToOffering(offering)}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="align-baseline">
                          {offering.name}
                          <div className="small text-muted">
                            {offering.issuer?.name}
                          </div>
                        </td>
                        <td className=" align-baseline">
                          {stockTypeName(offering.stock_type)}
                        </td>
                        <td className=" align-baseline">
                          {offering.launch_date &&
                            moment(offering.launch_date).format("ll")}
                        </td>
                        <td className=" align-baseline">
                          <NumberFormat
                            value={offering.total_amount}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={0}
                            prefix="$"
                          />
                        </td>
                        <td className=" align-baseline">
                          <StatusBadge status={offering.status} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spinner>
        </div>
        <PaginationRow
          name="offering"
          pagination={pagination}
          onPageChange={(page) => queryParams.set({ page })}
        />
      </div>
    </>
  );
};

export default IssuerOfferings;
