export const PersonaDashboardBase = new URL("https://app.withpersona.com");

export const INQUIRY_APPROVED = "approved";
export const INQUIRY_DECLINED = "declined";
export const INQUIRY_FOR_REVIEW = "needs_review";
export const INQUIRY_COMPLETED = "completed";
export const INQUIRY_PENDING = "pending";
export const INQUIRY_CREATED = "created";
export const INQUIRY_EXPIRED = "expired";
export const INQUIRY_FAILED = "failed";

export const INQUIRY_STATUS_ALERT_TYPE = {
  [INQUIRY_COMPLETED]: "teal",
  [INQUIRY_APPROVED]: "green",
  [INQUIRY_FOR_REVIEW]: "teal",
  [INQUIRY_PENDING]: "gray",
  [INQUIRY_CREATED]: "gray",
  [INQUIRY_DECLINED]: "red",
  [INQUIRY_EXPIRED]: "orange",
  [INQUIRY_FAILED]: "red",
};

export const INQUIRY_STATUS_TEXT = {
  [INQUIRY_COMPLETED]: "Completed",
  [INQUIRY_APPROVED]: "Approved",
  [INQUIRY_DECLINED]: "Declined",
  [INQUIRY_FOR_REVIEW]: "Needs Review",
  [INQUIRY_PENDING]: "Pending",
  [INQUIRY_CREATED]: "Created",
  [INQUIRY_EXPIRED]: "Expired",
  [INQUIRY_FAILED]: "Failed",
};

// @TODO use equidefi colors
export const INQUIRY_STATUS_CONFIG_MAP = {
  [INQUIRY_APPROVED]: {
    color: "green.500",
    bg: "green.50",
    border: "green.200",
    textColor: "green.600",
    display: "APPROVED",
  },
  [INQUIRY_COMPLETED]: {
    color: "yellow",
    bg: "yellow.50",
    border: "yellow.200",
    textColor: "yellow.600",
    display: "COMPLETED",
  },
  [INQUIRY_FOR_REVIEW]: {
    color: "yellow",
    bg: "yellow.50",
    border: "yellow.200",
    textColor: "yellow.600",
    display: "REVIEW",
  },
  [INQUIRY_CREATED]: {
    color: "gray",
    bg: "gray.50",
    border: "gray.200",
    textColor: "gray.600",
    display: "NOT STARTED",
  },
  [INQUIRY_PENDING]: {
    color: "gray",
    bg: "gray.50",
    border: "gray.200",
    textColor: "gray.600",
    display: "PENDING",
  },
  [INQUIRY_EXPIRED]: {
    color: "red",
    bg: "red.50",
    border: "red.200",
    textColor: "red.600",
    display: "EXPIRED",
  },
  [INQUIRY_FAILED]: {
    color: "red",
    bg: "red.50",
    border: "red.200",
    textColor: "red.600",
    display: "FAILED",
  },
  [INQUIRY_DECLINED]: {
    color: "red",
    bg: "red.50",
    border: "red.200",
    textColor: "red.600",
    display: "DECLINED",
  },
};

export const INQUIRY_STATUSES = [
  INQUIRY_APPROVED,
  INQUIRY_DECLINED,
  INQUIRY_FOR_REVIEW,
  INQUIRY_COMPLETED,
  INQUIRY_PENDING,
  INQUIRY_CREATED,
  INQUIRY_EXPIRED,
  INQUIRY_FAILED,
];
