/* eslint-disable react-hooks/exhaustive-deps */
import {
  useToast,
  Container,
  Grid,
  GridItem,
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
} from "@chakra-ui/react";
import Select from "react-select";
import { Pagination, Paper } from "@equidefi/ui";

import moment from "moment";
import { useQueryParams } from "@equidefi/portals/hooks/useQueryParam";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import Spinner from "@/components/spinner";
import { userFullName } from "@equidefi/shared";
import Header from "@/layouts/header";
import useDebounce from "@/hooks/useDebounce";
import { useSearchVault } from "@/hooks/useVault";
import { ACCREDITATION_STATUS_LIST } from "@/constants/vault";
import {useNavigate} from "react-router";

const rowHoverStyle = {
  background: "#f9fbfd",
  cursor: 'pointer'
}

const VaultIndex = () => {
  useDocumentTitle(["Vault Records"]);

  const navigate = useNavigate();
  const queryParams = useQueryParams({
    search: { type: "string" },
    status: { type: "string" },
    page: { type: "number" },
  });
  const searchVal = useDebounce(queryParams.get("search"), 500);
  const statusVal = useDebounce(queryParams.get("status"), 500);
  const pageVal = useDebounce(queryParams.get("page"), 500);

  const { data, isLoading } = useSearchVault({
    search: searchVal,
    status: statusVal,
    page: pageVal,
  });
  const { data: searchResults, pagination } = data ?? {};

  const statusOptions = ACCREDITATION_STATUS_LIST.map((status) => ({
    label: status,
    value: status,
  }));

  return (
    <Container maxW="full" px={0}>
      <Header name="Investor Vault Records"></Header>

      <Box px={8}>
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={9}>
            <Input
              placeholder="Search by first name, middle name, last name, company, email or phone"
              id="search-vault"
              label="Search Vault Records"
              defaultValue={queryParams.get("search")}
              onChange={(e) => queryParams.set({ search: e.target.value, page: 1 })}
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Select
              options={[{ label: "All", value: "" }, ...statusOptions]}
              defaultValue={queryParams.get("status")}
              onChange={({ value }) => queryParams.set({ status: value, page: 1 })}
            />
          </GridItem>
        </Grid>

        <Spinner show={isLoading}>
          <Paper mt={4}>
            <Table variant="unstyled">
              <Thead>
                <Tr borderColor="#f9fbfd">
                  <Th>Name</Th>
                  <Th>Company</Th>
                  <Th>Phone</Th>
                  <Th>Entity Type</Th>
                  <Th>Accreditation Status</Th>
                  <Th>Accreditation Method</Th>
                  <Th>Created At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchResults?.length === 0 && (
                  <Tr>
                    <Td colSpan={5} textAlign="center">No inquiries found</Td>
                  </Tr>
                )}
                {searchResults?.map((vault) => (
                  <Tr key={vault.id} _hover={rowHoverStyle} onClick={() => {
                    navigate(`/vault/${vault.id}/overview`, {state: vault})
                  }}>
                    <Td>{userFullName(vault)}</Td>
                    <Td>{vault.company}</Td>
                    <Td>{vault.phone_number}</Td>
                    <Td>{vault.entity_type}</Td>
                    <Td>{vault.status}</Td>
                    <Td>{vault.accreditation_method}</Td>
                    <Td>
                      {moment(vault.create_date).format("MM/DD/YYYY")}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Paper>
          <Flex justifyContent="end" my={4}>
            <Pagination
              size="sm"
              currentPage={pagination?.currentPage}
              totalPages={pagination?.totalPages}
              onGoToPage={(page) => queryParams.set({ page })}
            />
          </Flex>
        </Spinner>
      </Box>
    </Container>
  );
};

export default VaultIndex;
