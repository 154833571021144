import
  {
    Button,
    ButtonGroup,
    Flex,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    useDisclosure,
  } from "@chakra-ui/react";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import swal from "sweetalert";
import * as Yup from "yup";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";

import { Input } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import Spinner from "../../components/spinner";
import
  {
    useIssuer,
    useRoleCreate,
    useRoleDelete,
    useRoleList,
    useRoleUpdate,
  } from "../../hooks/useIssuers";
import Header from "../../layouts/header";

export const roleSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

const Roles = () => {
  const { id } = useParams();
  const { data: issuer } = useIssuer(id);
  const onDelete = useRoleDelete();
  const [role, setRole] = useState(null);

  const { data: roles, isLoading, refetch } = useRoleList(id);

  const { isOpen: editModal, onToggle: showEditModal } = useDisclosure();

  useDocumentTitle(["Roles", issuer?.name]);

  const handleDelete = useCallback(
    async ({ id: roleId, name }) => {
      const status = await swal({
        title: `Delete Role`,
        text: `Are you sure you want to delete the ${name} role?`,
        buttons: ["Cancel", "Yes"],
        icon: "error",
        dangerMode: true,
      });

      if (!status) return;

      await onDelete.mutateAsync({
        issuerId: issuer.id,
        roleId: roleId,
      });

      setRole(null);
      refetch();
    },
    [onDelete, issuer?.id, refetch]
  );

  return (
    <>
      <Header name="Roles" title={issuer?.name}>
        <Button onClick={() => showEditModal(true)}>New Role</Button>
      </Header>

      <div className="container-fluid">
        <div className="card">
          <Spinner show={isLoading}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {roles?.map((role, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-baseline">{role.name}</td>
                        <td className="text-end align-baseline">
                          <ButtonGroup colorScheme="gray" size="sm">
                            <Tooltip label="Edit Role" openDelay={700}>
                              <IconButton
                                icon={<Icon.Edit size=".9em" />}
                                aria-label="Edit"
                                onClick={() => {
                                  setRole({ name: role.name, id: role.id });
                                  showEditModal(true);
                                }}
                              />
                            </Tooltip>
                            <Tooltip label="Delete Role" openDelay={700}>
                              <IconButton
                                icon={<Icon.Trash size=".9em" />}
                                aria-label="Delete"
                                onClick={async () => {
                                  handleDelete({
                                    name: role.name,
                                    id: role.id,
                                  });
                                }}
                              />
                            </Tooltip>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spinner>
        </div>
      </div>

      <RoleEditModal
        show={editModal}
        onHide={() => {
          setRole(null);
          showEditModal(false);
          refetch();
        }}
        name={role?.name}
        issuerId={issuer?.id}
        roleId={role?.id}
      />
    </>
  );
};

const RoleEditModal = ({ issuerId, roleId, name, show, onHide }) => {
  const onCreate = useRoleCreate(issuerId);
  const onUpdate = useRoleUpdate(issuerId);

  const onSubmit = useCallback(
    async (values) => {
      if (roleId) {
        await onUpdate.mutateAsync({
          name: values.name,
          issuerId: issuerId,
          roleId: roleId,
        });
      } else {
        await onCreate.mutateAsync({
          name: values.name,
          issuerId: issuerId,
        });
      }

      onHide();
    },
    [issuerId, onCreate, onHide, onUpdate, roleId]
  );

  return (
    <Modal
      isOpen={show}
      trapFocus
      isCentered
      onClose={onHide}
      portalProps="appendToParentPortal"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{roleId ? "Edit" : "Add"} Role</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: name,
            }}
            validationSchema={roleSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors, touched, getFieldProps }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Input
                    label="Name"
                    isRequired
                    isInvalid={errors.name && touched.name}
                    placeholder="John"
                    error={errors.name}
                    {...getFieldProps("name")}
                  />
                  <Flex w="full" justify="end" my={2}>
                    <ButtonGroup>
                      <Button type="submit">Save</Button>
                      <Button colorScheme="gray" type="button" onClick={onHide}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Roles;
