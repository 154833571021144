/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Paper } from "@equidefi/ui";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Link } from "react-router";
import Swal from "sweetalert";

import
  {
    ButtonGroup,
    Flex,
    IconButton,
    Input,
    LinkBox,
    LinkOverlay,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useToast,
  } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";

import OfferingStatusBadge from "@/components/OfferingStatusBadge";
import Spinner from "@/components/spinner";
import Header from "@/layouts/header";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useQueryParams } from "@equidefi/portals/hooks/useQueryParam";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";

import { V2Button } from "@/components/V2Button";
import { isAdmin, stockTypeOfferingPage } from "@/helpers";
import useDebounce from "@/hooks/useDebounce";
import { useOfferings } from "@/hooks/useOfferings";
import { ButtonLink } from "@equidefi/portals/components/ButtonLink";

const OfferingList = (props) => {
  useDocumentTitle(["Offerings"]);

  const searchParams = useQueryParams({
    search: { type: "string" },
    page: { type: "number", defaultValue: 1 },
  });
  const searchVal = useDebounce(searchParams.get("search"), 500);
  const page = searchParams.get("page");
  const toast = useToast();

  const {
    data: offeringsData,
    refetch,
    isLoading: spinner,
  } = useOfferings({
    search: searchVal,
    page: page,
  });

  const { offerings, pagination } = offeringsData ?? {
    offerings: [],
    pagination: {},
  };

  const offeringApi = useApi(OfferingClient);
  const { data: me } = useCurrentUser();

  const deleteOffering = async (id) => {
    // API
    const status = await Swal({
      title: `Delete`,
      text: `Are you sure you want to delete it?`,
      buttons: ["Cancel", "Delete"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await offeringApi.remove(id);
      toast({ status: "success", description: "Offering has been deleted" });
      refetch();
    } catch (e) {
      toast({
        status: "error",
        description: "Something went wrong, please try again later.",
      });
    }
  };

  return (
    <>
      <Header name="Offerings">
        {isAdmin(me) && (
          <ButtonGroup size="sm" gap="2">
            <V2Button to={`/v2/offerings`} />

            <ButtonLink to="/offering/create">New Offering</ButtonLink>
          </ButtonGroup>
        )}
      </Header>

      <Flex flexDirection="column" gap={1} px={9}>
        <Input
          placeholder="Search by name, status..."
          id="search-offerings"
          label="Search Offerings"
          defaultValue={searchParams.get("search")}
          onChange={(e) =>
            searchParams.set({ search: e.target.value, page: 1 })
          }
        />

        <Paper>
          <Spinner show={spinner}>
            <TableContainer>
              <Table className="table table-nowrap table-hover mb-0">
                <Thead>
                  <Tr>
                    <Th>Offering</Th>
                    <Th>Type</Th>
                    <Th>Launch Date</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                    {isAdmin(me) && <Th className="">Action</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {offerings?.map((offering) => {
                    return (
                      <Tr key={offering.id}>
                        <LinkBox as={Td}>
                          <Tooltip
                            label={offering.name}
                            openDelay={1000}
                            placement="auto"
                          >
                            <Text
                              mb={0}
                              maxWidth={{ base: "35vw", md: "25em" }}
                              isTruncated
                            >
                              <LinkOverlay
                                as={Link}
                                to={`/offering/${offering.id}/overview`}
                              >
                                {offering.name}
                              </LinkOverlay>
                            </Text>
                          </Tooltip>
                          <Text as="span" className="small text-muted">
                            {offering.issuer?.name}
                          </Text>
                        </LinkBox>
                        <Td>{stockTypeOfferingPage(offering?.stock_type)}</Td>
                        <Td>
                          {offering.launch_date &&
                            moment(offering.launch_date).format("ll")}
                        </Td>
                        <Td>
                          <NumberFormat
                            value={offering.total_amount}
                            displayType="text"
                            thousandSeparator
                            decimalScale={0}
                            prefix="$"
                          />
                        </Td>
                        <Td>
                          <OfferingStatusBadge
                            status={offering.status}
                            fontSize=".8em"
                          />
                        </Td>
                        <Td>
                          {isAdmin(me) &&
                            (offering.status === "Draft" ||
                              offering.status === "In Review") && (
                              <Tooltip placement="auto" label="Delete">
                                <IconButton
                                  size="sm"
                                  colorScheme="gray"
                                  aria-label="Delete"
                                  icon={<Icon.Trash size="1em" />}
                                  onClick={() => deleteOffering(offering.id)}
                                ></IconButton>
                              </Tooltip>
                            )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>

            <Flex justify="end" m={4}>
              <Pagination
                size="sm"
                currentPage={pagination?.currentPage}
                totalPages={pagination?.totalPages}
                onGoToPage={(page) => searchParams.set({ page })}
              />
            </Flex>
          </Spinner>
        </Paper>
      </Flex>
    </>
  );
};

export default OfferingList;
