import {
  Container,
  Grid,
  GridItem,
  TabList,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router";

import { useInvestmentContext } from "./InvestmentContext";
import { InvestmentHeaderCard } from "./shared/InvestmentHeaderCard";
import { InvestmentSidebarCards } from "./shared/InvestmentSidebarCards";
import { InvestmentAreaContainerSkeleton } from "./skeletons/InvestmentAreaContainerSkeleton";

import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { Tab } from "@equidefi/ui";
import React, { useMemo } from "react";

export function InvestmentNavigation(): React.JSX.Element {
  const location = useLocation();

  const defaultIndex = useMemo(() => {
    if (location.pathname.includes("summary")) {
      return 0;
    } else if (location.pathname.includes("payments")) {
      return 1;
    } else if (location.pathname.includes("kyc")) {
      return 2;
    } else if (location.pathname.includes("agreements")) {
      return 3;
    } else if (location.pathname.includes("accreditation")) {
      return 4;
    } else if (location.pathname.includes("history")) {
      return 5;
    }
  }, [location]);

  return (
    <Tabs mb={2} variant="equidefi" index={defaultIndex}>
      <TabList>
        <Tab href={`./summary`}>Summary</Tab>
        <Tab href={`./payments`}>Payment</Tab>
        <Tab href={`./kyc`}>KYC</Tab>
        <Tab href={`./agreements`}>Agreement</Tab>
        <Tab href={`./accreditation`}>Accreditation</Tab>
        <Tab href={`./history`}>Activity Log</Tab>
      </TabList>
    </Tabs>
  );
}

export default function InvestmentAreaContainer(): React.JSX.Element {
  const { investment, isLoading } = useInvestmentContext();
  const { isLoading: isSignaturesLoading } = useInvestmentSignatures(
    investment?.id,
    {
      refetchInterval: closed ? 2_500 : false,
    }
  );

  if (isLoading || isSignaturesLoading || !investment) {
    return <InvestmentAreaContainerSkeleton />;
  }

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch">
        <InvestmentHeaderCard investment={investment} />
        <InvestmentNavigation />
        <Grid gap="4" templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Outlet />
          </GridItem>

          <GridItem>
            <InvestmentSidebarCards investment={investment} />
          </GridItem>
        </Grid>
      </VStack>
    </Container>
  );
}
