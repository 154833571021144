import { Button, ButtonProps, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";

import { extractErrorMessage } from "@equidefi/portals";
import { ConfirmModal } from "@equidefi/ui";

import ManualCancelModal from "@/components/investments/InvestmentPayments/ManualCancelModal";
import ManualConfirmModal from "@/components/investments/InvestmentPayments/ManualConfirmModal";
import ManualRefundModal from "@/components/investments/InvestmentPayments/ManualRefundModal";
import { usePaymentConfirm, useTerminatePayment } from "@/hooks/useStripe";
import { TPaymentDTO } from "@/types/dto";

interface TPaymentActionButtonProps extends ButtonProps {
  payment: TPaymentDTO;
  onSubmit: () => void;
}

export const RefundPaymentButton: React.FC<TPaymentActionButtonProps> = (
  props
) => {
  const { payment, onSubmit, ...rest } = props;
  const modal = useDisclosure();

  if (!payment || payment?.status !== "succeeded") return null;

  return (
    <>
      <Button colorScheme="red" onClick={modal.onToggle} {...rest}>
        Refund Payment
      </Button>

      <ManualRefundModal
        isOpen={modal.isOpen}
        investmentId={payment.investment_id}
        paymentId={payment.id}
        onHide={() => modal.onClose()}
        onSubmit={onSubmit}
      />
    </>
  );
};

export const CaptureStripePaymentButton: React.FC<TPaymentActionButtonProps> = (
  props
) => {
  const toast = useToast();
  const { payment, onSubmit, ...rest } = props;
  const capturePayment = usePaymentConfirm();
  const modal = useDisclosure();

  const handleCapturePayment = async () => {
    try {
      await capturePayment.mutateAsync({
        investmentId: payment.investment_id,
        paymentId: payment.id,
      });
      toast({
        status: "success",
        description: "Payment captured successfully!",
      });
      setTimeout(() => {
        onSubmit();
      }, 2000);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: extractErrorMessage(
          error,
          "Payment is not captured successfully!"
        ),
      });
    }
  };

  if (!payment || payment?.status !== "requires_capture") return null;

  return (
    <>
      <Button onClick={modal.onToggle} {...rest}>
        Capture Payment
      </Button>
      <ConfirmModal
        onConfirm={handleCapturePayment}
        onCancel={modal.onClose}
        isOpen={modal.isOpen}
        title="Capture Payment?"
        description="Are you sure ready to capture this payment?"
      />
    </>
  );
};

export const TerminateStripePaymentButton: React.FC<
  TPaymentActionButtonProps
> = (props) => {
  const toast = useToast();
  const { payment, onSubmit, ...rest } = props;
  const terminatePayment = useTerminatePayment();
  const modal = useDisclosure();

  if (!payment || payment?.status !== "requires_capture") return null;

  const handleTerminatePayment = async () => {
    try {
      await terminatePayment.mutateAsync({
        investmentId: payment.investment_id,
        paymentId: payment.id,
      });
      toast({ status: "success", description: "Payment was terminated" });
      setTimeout(() => {
        onSubmit();
      }, 2000);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: extractErrorMessage(
          error,
          "Payment could not be terminated."
        ),
      });
    }
  };

  return (
    <>
      <Button colorScheme="red" onClick={modal.onToggle} {...rest}>
        Cancel Authorization
        <ConfirmModal
          onConfirm={handleTerminatePayment}
          onCancel={modal.onClose}
          isOpen={modal.isOpen}
          confirmButtonColor="red"
          title="Cancel Authorization?"
          description="Are you sure you want to cancel this authorization?"
        />
      </Button>
    </>
  );
};

export const ConfirmManualPaymentButton: React.FC<TPaymentActionButtonProps> = (
  props
) => {
  const { payment, onSubmit, ...rest } = props;
  const modal = useDisclosure();

  if (!payment || payment?.status !== "created") return null;

  return (
    <>
      <Button onClick={modal.onToggle} {...rest}>
        Confirm Receipt of Payment
      </Button>

      <ManualConfirmModal
        isOpen={modal.isOpen}
        investmentId={payment.investment_id}
        paymentId={payment.id}
        onHide={() => modal.onClose()}
        onSubmit={onSubmit}
      />
    </>
  );
};

export const CancelManualPaymentButton: React.FC<TPaymentActionButtonProps> = (
  props
) => {
  const { payment, onSubmit, ...rest } = props;
  const modal = useDisclosure();

  if (!payment || payment?.status !== "created") return null;

  return (
    <>
      <Button onClick={modal.onToggle} {...rest}>
        Cancel Payment
      </Button>

      <ManualCancelModal
        isOpen={modal.isOpen}
        investmentId={payment.investment_id}
        paymentId={payment.id}
        onHide={() => modal.onClose()}
        onSubmit={onSubmit}
      />
    </>
  );
};
