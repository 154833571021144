// import { Modal, Tab, Tabs } from "react-bootstrap";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { StatusBadge } from "@equidefi/portals/components/investments/StatusBadge";

import {
  DateTimeService,
  MomentDateTimeAdapter,
} from "@/services/DateTimeService";

const dateFormat = new DateTimeService(new MomentDateTimeAdapter());

const TrauncheInvestmentModal = ({ investment = null, onHide = () => {} }) => {
  if (!investment) return null;

  return (
    <Modal
      size="xl"
      isOpen
      isCentered
      onClose={onHide}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxH="80vh">
        <ModalHeader>
          <h3 className="fs-1 mb-0">{investment.vault.display_name}</h3>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="scroll" py="4">
          <TableContainer>
            <Table colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>Event</Th>
                  <Th>Action</Th>
                  <Th>User/Actor</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {investment?.event_history?.map((event) => {
                  return (
                    <Tr key={event.id}>
                      <Td>{event.workflow_event}</Td>
                      <Td>
                        <StatusBadge action={event.workflow_action} />
                      </Td>
                      <Td>{event.display_name ?? "Equidefi Admin"}</Td>
                      <Td>
                        {dateFormat.formatMonthDayYear(event.create_date)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrauncheInvestmentModal;
