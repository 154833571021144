import
  {
    Alert,
    AlertDescription,
    AlertIcon,
    ButtonGroup,
    IconButton,
    Stack,
    Tooltip,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import DownloadButton from "@equidefi/portals/components/investments/DownloadButton";
import { useEmbeddedSignature } from "@equidefi/portals/hooks/useSignatures";
import { Heading, Paper } from "@equidefi/ui";
import CountersignButton from "./CountersignButton";

import VoidAgreementButton from "@/areas/Investments/shared/VoidAgreementButton";
import InvestmentSignatureList from "@equidefi/portals/components/investments/InvestmentSigantureList";
import { SignerModal } from "@equidefi/portals/components/SignerModal";
import { useInvestmentSignatures } from "@equidefi/portals/hooks/useAgreements";
import { useInvestmentSigners } from "@equidefi/portals/hooks/useSigners";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Icon } from "@equidefi/ui/icon";
import { CONFIG } from "../../../constants/config";
import { isAdmin, isOfferingEditor, isOfferingOwner } from "../../../helpers";
import { InvestmentAgreementSupportModal } from "./InvestmentAgreementSupportModal";
import SetupAgreementButton from "./SetupAgreementButton";

const InvestentAgreementCard = ({
  agreement,
  investmentId,
  signingParties = [],
  isCountersignable = false, // investment-level countersigning status
  onCountersign = async () => {},
}) => {
  const { id: offeringId } = useParams();
  const [closed, setClosed] = useState(false);
  const toast = useToast();
  const signerModal = useDisclosure();
  const supportModal = useDisclosure();

  const { data: user } = useCurrentUser();
  const { data, refetch: refetchSignatures } = useInvestmentSignatures(
    investmentId,
    {
      refetchInterval: closed ? 2_500 : false,
    }
  );
  const investors = data?.investors;
  const countersigner = data?.countersigner;
  const remindableId = data?.remindable?.id;

  const signers = useInvestmentSigners(investmentId, user?.entity_type, {
    onSuccess: () => {
      signerModal.onClose();
    },
  });

  const showVoidAgreement = useMemo(
    () => isAdmin(user) || isOfferingOwner(user, offeringId),
    [user, offeringId]
  );

  const showSetupAgreement = useMemo(
    () =>
      isAdmin(user) ||
      isOfferingOwner(user, offeringId) ||
      isOfferingEditor(user, offeringId),
    [user, offeringId]
  );

  const canEditSigners = useMemo(
    () => isAdmin(user) && !agreement,
    [user, agreement]
  );

  const counterSignature = useEmbeddedSignature({
    testMode: CONFIG.ENV !== "production",
    skipDomainVerification: CONFIG.ENV !== "production",
    onError: () => {
      toast({ status: "success", description: "Countersigning failed" });
    },
    onCancel: () => {
      toast({ status: "info", description: "Countersigning incomplete" });
    },
    onSign: async () => {
      setClosed(true);
      onCountersign();
    },
    onFinish: () => {
      toast({ status: "success", description: "Countersigning completed!" });
    },
  });

  useEffect(() => {
    if (data?.is_countersigned) setClosed(false);
  }, [closed, data?.is_countersigned]);

  const onCountersignClick = useCallback(async () => {
    try {
      await counterSignature.start(agreement?.id, countersigner?.signature_id);
    } catch (e) {
      toast({
        status: "error",
        description: "Countersigning could not be started.",
      });
    }
  }, [agreement?.id, counterSignature, countersigner?.signature_id, toast]);
  return (
    <Paper mb={8} boxShadow="none" p="5">
      <Stack direction="row" justify="space-between" w="full">
        <Heading textStyle="h2">Subscription Agreement</Heading>
        <ButtonGroup size="sm" gap="2">
          {isAdmin(user) && agreement && (
            <Tooltip label="Support" hasArrow>
              <IconButton
                icon={<Icon.Sliders size="1em" />}
                colorScheme="gray"
                variant="ghost"
                onClick={() => supportModal.onOpen()}
              />
            </Tooltip>
          )}
          {!agreement && showSetupAgreement && (
            <SetupAgreementButton
              investmentId={investmentId}
              variant="outline"
              onSetup={() => refetchSignatures()}
            />
          )}
          {showVoidAgreement && agreement?.status !== "COMPLETED" && (
            <VoidAgreementButton
              agreement={agreement}
              variant="outline"
              onVoided={() => refetchSignatures()}
            >
              Cancel Agreement
            </VoidAgreementButton>
          )}
          <DownloadButton
            investmentId={agreement?.investment_id}
            variant="outline"
          >
            Download
          </DownloadButton>
          <CountersignButton
            investmentId={agreement?.investment_id}
            onClick={onCountersignClick}
            isCountersignable={isCountersignable}
          >
            Countersign
          </CountersignButton>
        </ButtonGroup>
      </Stack>

      {investors?.length ? (
        <>
          <Heading textStyle="h3" fontSize="xs" textTransform="uppercase">
            Investors{" "}
            {canEditSigners && signers.canAddMoreSigners && (
              <IconButton
                size="1em"
                onClick={() => {
                  signers.onCreate();
                  signerModal.onOpen();
                }}
                variant="none"
                icon={<Icon.PlusCircle size="1em" />}
                marginLeft="auto"
                mb="0.5"
                color="equidefi.red"
              />
            )}
          </Heading>
          <InvestmentSignatureList
            investmentId={investmentId}
            signingParties={investors}
            remindableId={remindableId}
            onEdit={(data) => {
              signers.onEdit(data);
              signerModal.onOpen();
            }}
            onDelete={signers.onDelete}
            canEditSigners={canEditSigners}
          />
          <Heading textStyle="h3" fontSize="xs" textTransform="uppercase">
            Countersigner
          </Heading>
          <InvestmentSignatureList
            investmentId={investmentId}
            signingParties={[countersigner]}
          />
        </>
      ) : (
        <Alert status="info" variant="top-accent">
          <AlertIcon />
          <AlertDescription>
            No signers have been added to this investment yet.
          </AlertDescription>
        </Alert>
      )}
      {isAdmin(user) && (
        <SignerModal
          isOpen={signerModal.isOpen}
          initialValues={signers.initialValues}
          onHide={signerModal.onClose}
          onSubmit={signers.onSubmit}
        />
      )}
      <InvestmentAgreementSupportModal
        isOpen={supportModal.isOpen}
        onClose={supportModal.onClose}
        investmentAgreementId={agreement?.id}
      />
    </Paper>
  );
};

export default InvestentAgreementCard;
