import { useMutation, useQuery } from "@tanstack/react-query";

import { useApi } from "@equidefi/portals/hooks/useApi";

import StripeClient from "@/clients/StripeClient";

type TPaymentMutParams = {
  investmentId: string;
  paymentId: string;
};

export const useStripeSetup = (issuerId: string) => {
  const { mutateAsync, isLoading, error } = useMutation(() =>
    StripeClient.createSetupIntent(issuerId)
  );

  return {
    setupMutate: mutateAsync,
    setupLoading: isLoading,
    setupError: error,
  };
};

export const usePaymentConfirm = () => {
  const stripeApi = useApi(StripeClient);

  return useMutation(({ investmentId, paymentId }: TPaymentMutParams) =>
    stripeApi.confirmPayment(investmentId, paymentId)
  );
};

export const useTerminatePayment = () => {
  const stripeApi = useApi(StripeClient);

  return useMutation(({ investmentId, paymentId }: TPaymentMutParams) =>
    stripeApi.terminatePayment(investmentId, paymentId)
  );
};

export const usePaymentMethod = (issuerId: string, setupIntentId: string) => {
  const { mutateAsync, isLoading, error } = useMutation(() =>
    StripeClient.savePaymentMethod(issuerId, setupIntentId)
  );

  return {
    paymentMutate: mutateAsync,
    setupLoading: isLoading,
    setupError: error,
  };
};

export const useGetPaymentMethodDomain = (offeringId: string, options = {}) => {
  const stripeApi = useApi(StripeClient);

  return useQuery(
    ["stripe", "payment-method-domain", offeringId],
    () => stripeApi.getPaymentMethodDomain(offeringId),
    {
      enabled: !!offeringId,
      ...options,
    }
  );
};

export const useCreatePaymentMethodDomain = (
  offeringId: string,
  options = {}
) => {
  const stripeApi = useApi(StripeClient);

  return useMutation(
    (enabled) => stripeApi.createPaymentMethodDomain(offeringId, enabled),
    options
  );
};

export const getSetupIntent = (issuerId: string) => {
  return new Promise(function (resolve, reject) {
    StripeClient.getSetupIntent(issuerId)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getPaymentMethod = (issuerId: string) => {
  return new Promise(function (resolve, reject) {
    StripeClient.getPaymentMethod(issuerId)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
