/* eslint-disable react-hooks/exhaustive-deps */
import { useToast, Container, Grid, GridItem, Flex, Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Input } from "@chakra-ui/react";
import Select from "react-select";
import { Pagination, Paper } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import moment from "moment";
import Swal from "sweetalert";
import { useQueryParams } from "@equidefi/portals/hooks/useQueryParam";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import Spinner from "@/components/spinner";
import Header from "@/layouts/header";
import useDebounce from "@/hooks/useDebounce";
import { useInquiries, useDeleteInquiry } from "@/hooks/useInquiries";
import { INQUIRY_STATUSES } from "@/constants/inquiries";


const InquiriesIndex = () => {
  useDocumentTitle(["Inquiries"]);

  const queryParams = useQueryParams({
    search: { type: "string" },
    status: { type: "string" },
    page: { type: "number" },
  });
  const searchVal = useDebounce(queryParams.get("search"), 500);
  const statusVal = useDebounce(queryParams.get("status"), 500);
  const pageVal = useDebounce(queryParams.get("page"), 500);

  const toast = useToast();

  const { data, isLoading, refetch: refetchInquiries } = useInquiries({
    search: searchVal,
    status: statusVal,
    page: pageVal,
  });
  const { data: inquiries, pagination } = data ?? {};
  const deleteInquiry = useDeleteInquiry();

  const statusOptions = INQUIRY_STATUSES.map((status) => ({
    label: status,
    value: status,
  }));

  const handleDelete = async (id) => {
    const status = await Swal({
      title: `Delete`,
      text: `Are you sure you want to delete it?`,
      buttons: ["Cancel", "Delete"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await deleteInquiry.mutateAsync(id);
      toast({
        status: "success",
        description: "Inquiry was successfully deleted.",
      });
      queryParams.set({ page: 1, search: "", status: "" });
      refetchInquiries();
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        description: "Failed to delete inquiry.",
      });
    }
  };

  return (
    <Container maxW="full" px={0}>
      <Header name="Inquiries"></Header>

      <Box px={8}>
        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={9}>
            <Input
              placeholder="Search by Inquiry ID, Persona Inquiry ID, or User ID"
              id="search-inquiries"
              label="Search Inquiries"
              defaultValue={queryParams.get("search")}
              onChange={(e) => queryParams.set({ search: e.target.value, page: 1 })}
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Select
              options={[{ label: "All", value: "" }, ...statusOptions]}
              defaultValue={queryParams.get("status")}
              onChange={({ value }) => queryParams.set({ status: value, page: 1 })}
            />
          </GridItem>
        </Grid>

        <Spinner show={isLoading}>
          <Paper mt={4}>
            <Table variant="unstyled">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Inquiry ID</Th>
                  <Th>User ID</Th>
                  <Th>Status</Th>
                  <Th>Created At</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {inquiries?.length === 0 && (
                  <Tr>
                    <Td colSpan={5} textAlign="center">No inquiries found</Td>
                  </Tr>
                )}
                {inquiries?.map((inquiry) => (
                  <Tr key={inquiry.id}>
                    <Td>{inquiry.id}</Td>
                    <Td>{inquiry.inquiry_id}</Td>
                    <Td>{inquiry.user_id}</Td>
                    <Td>{inquiry.status}</Td>
                    <Td>
                      {moment(inquiry.create_date).format("MM/DD/YYYY")}
                    </Td>
                    <Td>
                      <IconButton
                        size="sm"
                        variant="ghost"
                        onClick={() => handleDelete(inquiry.id)}
                      >
                        <Icon.Trash size={16} />
                      </IconButton>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Paper>
          <Flex justifyContent="end" my={4}>
            <Pagination
              size="sm"
              currentPage={pagination?.currentPage}
              totalPages={pagination?.totalPages}
              onGoToPage={(page) => queryParams.set({ page })}
            />
          </Flex>
        </Spinner>
      </Box>
    </Container>
  );
};

export default InquiriesIndex;
