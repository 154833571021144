/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import { useParams } from "react-router";

import moment from "moment";
import swal from "sweetalert";

import { Button, ButtonGroup, IconButton, useToast } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { Icon } from "@equidefi/ui/icon";
import { AgreementUploadModal } from "../../components/offerings/AgreementUploadModal";
import Spinner from "../../components/spinner";
import {
    deleteOfferingAgreement,
    isAdmin,
    isOfferingEditor,
    isOfferingOwner,
} from "../../helpers";
import { useOffering } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const Agreements = (props) => {
  const { id } = useParams();
  const { data: offering, refetch: refetchOffering } = useOffering(id);
  const { data: me } = useCurrentUser();

  useDocumentTitle(["Agreements", offering?.name]);

  const toast = useToast();

  const [showModal, setShowModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [agreement, setAgreement] = useState({});

  const handleDelete = (agreement) => {
    swal({
      title: `Remove Agreement`,
      text: `Are you sure you want to remove '${agreement.name}'?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        deleteOfferingAgreement(offering.id, agreement.id).then(
          async (response) => {
            toast({
              status: "success",
              description: `${agreement.name} has been removed`,
            });
            await refetchOffering();
            showSpinner(false);
          }
        );
      }
    });
  };

  const canEdit = useMemo(
    () =>
      (isAdmin(me) ||
        isOfferingEditor(me, offering?.id) ||
        isOfferingOwner(me, offering?.id)) &&
      ["In Review", "Draft"].includes(offering?.status),
    [me, offering?.id, offering?.status]
  );

  return (
    <>
      <Header
        id={id}
        name="Execution Documents"
        iconName="fe fe-info ms-2"
        title={offering?.name}
        tooltipContent="Please upload Word versions of only the agreements your investors will be executing. We will label these documents in DocuSign for signature by the appropriate parties"
      >
        {canEdit && (
          <Button
            onClick={() => {
              setAgreement({});
              setShowModal(true);
            }}
          >
            Upload Agreements
          </Button>
        )}
      </Header>

      <Spinner show={spinner}>
        <div className="container-fluid">
          <div className="card">
            <table className="table table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>Agreement Name</th>
                  <th>Date Uploaded</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {offering?.agreements?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start align-baseline">
                        {item.name}{" "}
                        {item.template_id && (
                          <i
                            className="fe fe-file-text text-muted ms-3"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`${item.template_id}`}
                          ></i>
                        )}
                      </td>
                      <td className="align-baseline">
                        {item.create_date &&
                          moment(item.create_date).format("ll")}
                      </td>
                      <td className="text-end align-baseline">
                        <ButtonGroup size="sm" colorScheme="gray">
                          <IconButton
                            as="a"
                            icon={<Icon.Download size="1em" />}
                            href={item.agreement_url}
                            target="_blank"
                            rel="noreferrer"
                            cursor="pointer"
                          />
                          {canEdit && (
                            <IconButton
                              icon={<Icon.Edit size="1em" />}
                              aria-label="Edit Agreement"
                              onClick={() => {
                                setAgreement(item);
                                setShowModal(true);
                              }}
                            />
                          )}
                          {canEdit && (
                            <IconButton
                              icon={<Icon.Trash size="1em" />}
                              aria-label="Delete Agreement"
                              onClick={() => handleDelete(item)}
                            />
                          )}
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <AgreementUploadModal
            agreement={agreement}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            offering={offering}
            onComplete={refetchOffering}
          />
        </div>
      </Spinner>
    </>
  );
};

export default Agreements;
