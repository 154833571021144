/* eslint-disable react-hooks/exhaustive-deps */
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    ButtonGroup,
    Checkbox,
    Code,
    SimpleGrid,
    Spinner,
    Table,
    Td,
    Th,
    Tooltip,
    Tr,
    useToast,
    VStack,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import { useFormik } from "formik";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";

import { Heading, Input, Paper } from "@equidefi/ui";

import { useOffering, useOfferingUpdate } from "@/hooks/useOfferings";
import {
    useCreatePaymentMethodDomain,
    useGetPaymentMethodDomain,
} from "@/hooks/useStripe";
import Header from "@/layouts/header";

import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";

const OfferingSlugForm = ({ offering, isLoading = false, onSubmit }) => {
  const form = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
    initialValues: {
      slug: offering?.slug,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Input
        isRequired
        label="Slug"
        {...form.getFieldProps("slug")}
        placeholder="e.g. equidefi-01"
      ></Input>
      <Button type="submit" mt="2" isLoading={isLoading}>
        Update
      </Button>
    </form>
  );
};

const OfferingFeesForm = ({ offering, isLoading = false, onSubmit }) => {
  const form = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
    initialValues: {
      stripe_account_id: offering?.stripe_account_id,
      ach_fee: offering?.ach_fee,
      credit_card_fee: offering?.credit_card_fee,
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Input
        isRequired
        label="Stripe Connect Account ID"
        {...form.getFieldProps("stripe_account_id")}
        placeholder="e.g. acct_12093812398"
      />
      <Input
        label="ACH Fee"
        as={NumberFormat}
        isRequired
        allowNegative={false}
        onValueChange={({ value }) => {
          form.setFieldValue("ach_fee", value);
        }}
        decimalScale={3}
        {...form.getFieldProps("ach_fee")}
      />
      <Input
        label="Credit Card Fee"
        as={NumberFormat}
        isRequired
        allowNegative={false}
        onValueChange={({ value }) => {
          form.setFieldValue("credit_card_fee", value);
        }}
        decimalScale={3}
        {...form.getFieldProps("credit_card_fee")}
      />
      <Button type="submit" mt="2" isLoading={isLoading}>
        Update
      </Button>
    </form>
  );
};

const OfferingStripeDomain = ({ offering }) => {
  const toast = useToast();
  const {
    data: paymentMethodDomain,
    refetch,
    isLoading,
  } = useGetPaymentMethodDomain(offering?.id);
  const createPaymentMethodDomain = useCreatePaymentMethodDomain(offering?.id, {
    onSuccess: () => {
      toast({
        status: "success",
        description: `Successfully ${
          paymentMethodDomain?.data?.length ? "updated" : "registered"
        } current domain`,
      });
    },
  });
  const [enabled, setEnabled] = useState(false);

  if (isLoading) return <Spinner />;

  return (
    <VStack align="start">
      {paymentMethodDomain?.data?.length && (
        <Table>
          <Tr>
            <Th>Domain Name</Th>
            <Th>Enabled</Th>
            <Th>Google Pay</Th>
            <Th>Apple Pay</Th>
            <Th>Amazon Pay</Th>
            <Th>Link</Th>
            <Th>Paypal</Th>
          </Tr>
          {paymentMethodDomain?.data.map((payment) => {
            return (
              <Tr>
                <Td>{payment.domain_name}</Td>
                <Td>
                  <Tooltip>{String(payment.enabled)}</Tooltip>
                </Td>
                <Td>
                  <Tooltip
                    label={payment.google_pay?.status_details?.error_message}
                  >
                    {payment.google_pay.status}
                  </Tooltip>
                </Td>
                <Tooltip
                  hasArrow
                  label={payment.apple_pay?.status_details?.error_message}
                >
                  <Td>{payment.apple_pay.status}</Td>
                </Tooltip>
                <Td>
                  <Tooltip
                    label={payment.amazon_pay?.status_details?.error_message}
                  >
                    {payment.amazon_pay.status}
                  </Tooltip>
                </Td>
                <Td>
                  <Tooltip label={payment.link?.status_details?.error_message}>
                    {payment.link.status}
                  </Tooltip>
                </Td>
                <Td>
                  <Tooltip
                    label={payment.paypal?.status_details?.error_message}
                  >
                    {payment.paypal.status}
                  </Tooltip>
                </Td>
              </Tr>
            );
          })}
        </Table>
      )}

      <ButtonGroup mt="4">
        <Button
          onClick={async () => {
            await createPaymentMethodDomain.mutateAsync(enabled);
            await refetch();
          }}
        >
          {paymentMethodDomain?.data?.length
            ? "Update domain"
            : "Register domain"}
        </Button>
        <Button
          onClick={async () => {
            const content = document.createElement("pre");
            content.innerText = JSON.stringify(paymentMethodDomain, null, 2);
            content.style.textAlign = "left";

            sweetAlert({
              title: `Raw data:`,
              content: content,
            });
          }}
        >
          Show Raw
        </Button>
      </ButtonGroup>
      <Checkbox checked={enabled} onChange={() => setEnabled((prev) => !prev)}>
        Enabled
      </Checkbox>
    </VStack>
  );
};

const OfferingAdmin = () => {
  const { id } = useParams();
  const toast = useToast({ status: "success" });

  const { data: offering } = useOffering(id);
  const offeringUpdate = useOfferingUpdate(id);

  const handleUpdate = async (data) => {
    try {
      await offeringUpdate.mutateAsync(data);
      toast({ description: "Successfully updated the offering." });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toast({ status: "error", description: "Something went wrong" });
    }
  };

  useDocumentTitle(["Admin", offering?.name]);

  return (
    <>
      <Header name="Admin" title={offering?.name} />
      <div className="container-fluid">
        <Alert status="warning" variant="left-accent" mt="4">
          <AlertIcon />
          <div>
            <AlertTitle>This offering is active!</AlertTitle>
            <AlertDescription>
              Do not change these values unless you are 100% sure you know what
              you are doing... Marc...
            </AlertDescription>
          </div>
        </Alert>
        <SimpleGrid columns={{ base: 1, lg: 2 }} gap="4" mt="4">
          <div>
            <Paper p="4">
              <Heading>Offering Slug</Heading>
              <OfferingSlugForm
                offering={offering}
                isLoading={offeringUpdate.isLoading}
                onSubmit={handleUpdate}
              />
            </Paper>
          </div>
          <div>
            <Paper p="4">
              <Heading>Stripe and Fees</Heading>
              <Alert mb="3">
                <AlertDescription>
                  These values need to be entered as multipliers. For example, a
                  0.6% fee should be entered as{" "}
                  <Code colorScheme="gray">0.006</Code>.
                </AlertDescription>
              </Alert>
              <OfferingFeesForm
                offering={offering}
                isLoading={offeringUpdate.isLoading}
                onSubmit={handleUpdate}
              />
            </Paper>
          </div>
        </SimpleGrid>
        <div>
          <Paper mt="4" p="4">
            <Heading>Stripe Payment Method Domain</Heading>
            <OfferingStripeDomain offering={offering} />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default OfferingAdmin;
