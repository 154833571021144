import {
  Input,
  InputGroup,
  InputGroupProps,
  InputRightElement,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import { useEffect, useState } from "react";

type TSearchBarProps = InputGroupProps & {
  defaultSearchPhrase?: string;
  onClear?: () => void;
  onSearch?: (phrase: string) => void;
  placeholder?: string;
};

const SearchBar: React.FC<TSearchBarProps> = ({
  defaultSearchPhrase = "",
  onClear = () => {},
  onSearch = () => {},
  placeholder,
  ...rest
}) => {
  const [searchPhrase, setSearchPhrase] = useState(defaultSearchPhrase);

  useEffect(() => {
    if (!searchPhrase && defaultSearchPhrase) {
      onClear();
    }
  }, [searchPhrase, onClear, defaultSearchPhrase]);

  const handleClear = () => {
    setSearchPhrase("");
    onClear();
  };

  return (
    <InputGroup size="sm" {...rest}>
      <Input
        type="text"
        placeholder={placeholder}
        borderRadius="md"
        onChange={(e) => setSearchPhrase(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") onSearch(searchPhrase);
        }}
        value={searchPhrase}
      />
      <InputRightElement
        children={
          searchPhrase ? (
            <Icon.XCircle
              cursor="pointer"
              color="gray"
              size="1.4em"
              onClick={handleClear}
            />
          ) : null
        }
      />
    </InputGroup>
  );
};

export default SearchBar;
