import
  {
    Button,
    Center,
    Container,
    Heading,
    Image,
    Stack,
    Text, useToast
  } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation, useNavigate } from "react-router";

import { ResendMfaLink } from "@equidefi/portals/components/ResendMfaLink";
import
  {
    useLogin,
    useMFACode,
  } from "@equidefi/portals/hooks/useAuthentication";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import { BackButton } from "@equidefi/ui";


const MFA = () => {
  useDocumentTitle(["Verify Your Login"], "issuer");

  const navigate = useNavigate();
  const toast = useToast();

  const userData = useCurrentUser();
  const submitMFA = useMFACode();
  const login = useLogin();
  const [mfaCode, setMfaCode] = useState("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState();

  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await submitMFA.mutateAsync({
        email: location.state?.email,
        mfa_code: mfaCode,
      });

      userData.refetch();
      navigate("/offering");
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        description: "Incorrect code, please try again",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      location.state &&
      (!location.state?.password || !location.state?.email)
    ) {
      toast({
        status: "error",
        description: "Something went wrong. Please login again.",
      });
      history.push("/");
    }
  }, [location]);

  return (
    <section className="vh-100 d-flex align-items-center bg-auth border-top border-top-2 border-primary">
      <Container maxW="lg">
        <form onSubmit={handleLogin}>
          <Stack justify="center" align="center">
            <Stack spacing="3">
              <Center>
                <Image
                  src="/img/logo.svg"
                  w="200px"
                  mb="4"
                  alt="Equidefi Logo"
                  display="bock"
                />
              </Center>

              <Heading size="lg" as="h1" textAlign="center" mb="0">
                Two Factor Authentication
              </Heading>

              <Text textAlign="center" mb="4">
                Your account is protected with two-factor authentication. We've
                sent you an email. Please enter the code below.
              </Text>

              <ResendMfaLink
                authFunction={login}
                alignSelf="center"
                hasCountdown={false}
                state={location.state}
              />
            </Stack>

            <NumberFormat
              name="mfa_code"
              className="form-control mfa-code"
              format="######"
              mask="_"
              placeholder="0 0 0 0 0 0"
              value={mfaCode}
              onValueChange={({ value }) => setMfaCode(value)}
              autoComplete="off"
            />

            <BackButton
              textAlign="center"
              text="Back to login"
              onClick={() => navigate("/")}
            />

            <Button
              size="lg"
              isLoading={isLoading}
              isDisabled={mfaCode.length !== 6}
              type="submit"
              mt="4"
            >
              Continue
            </Button>
          </Stack>
        </form>
      </Container>
    </section>
  );
};

export default MFA;
