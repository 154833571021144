import React from 'react';
import {
  IconButton,
  Tooltip,
  useMultiStyleConfig,
  IconButtonProps,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import { V2Theme } from "../../theme";

export type ChipVariant = "unknown" | "pending" | "success" | "error" | "expedited" | "info";

interface ChipProps extends Omit<IconButtonProps, 'icon' | 'size' | 'aria-label'> {
  size?: "sm" | "md" | "lg";
  variant?: ChipVariant;
  tooltipText?: string;
  'aria-label'?: string;
}

const BaseChip: React.FC<ChipProps & { icon: React.ReactElement; defaultAriaLabel: string }> = ({ 
  size = "sm",
  variant = "unknown",
  tooltipText,
  icon,
  'aria-label': ariaLabel,
  defaultAriaLabel,
  ...props 
}) => {
  const styles = useMultiStyleConfig("InvestmentProgressChips", {
    size,
    variant,
    theme: V2Theme,
  });

  const button = (
    <IconButton
      {...props}
      size={size}
      aria-label={ariaLabel || defaultAriaLabel}
      icon={React.cloneElement(icon, { size: "1em" })}
      __css={styles.chip}
    />
  );

  if (tooltipText) {
    return (
      <Tooltip label={tooltipText} hasArrow placement="top">
        {button}
      </Tooltip>
    );
  }

  return button;
};

const Payment: React.FC<ChipProps> = (props) => (
  <BaseChip
    icon={<Icon.DollarSign />}
    defaultAriaLabel="Payment Status Icon"
    {...props}
  />
);

const KYC: React.FC<ChipProps> = (props) => (
  <BaseChip
    icon={<Icon.Search />}
    defaultAriaLabel="KYC/KYB Status Icon"
    {...props}
  />
);

const Agreement: React.FC<ChipProps> = (props) => (
  <BaseChip
    icon={<Icon.Edit3 />}
    defaultAriaLabel="Agreement Status Icon"
    {...props}
  />
);

const Expedited: React.FC<ChipProps> = (props) => (
  <BaseChip
    icon={<Icon.FastForward />}
    defaultAriaLabel="Expedited Status Icon"
    {...props}
  />
);

const Accreditation: React.FC<ChipProps> = (props) => (
  <BaseChip
    icon={<Icon.File />}
    defaultAriaLabel="Accreditation Status Icon"
    {...props}
  />
);

const Returning: React.FC<ChipProps> = (props) => (
  <BaseChip
    icon={<Icon.RefreshCw />}
    defaultAriaLabel="Returning Investor Status Icon"
    {...props}
  />
);

const Notes: React.FC<ChipProps> = (props) => (
  <BaseChip
    icon={<Icon.MessageSquare />}
    defaultAriaLabel="Notes Status Icon"
    {...props}
  />
);

type ChipsType = {
  Payment: React.FC<ChipProps>;
  KYC: React.FC<ChipProps>;
  Agreement: React.FC<ChipProps>;
  Expedited: React.FC<ChipProps>;
  Accreditation: React.FC<ChipProps>;
  Returning: React.FC<ChipProps>;
  Notes: React.FC<ChipProps>;
  displayName?: string;
};

export const Chips: ChipsType = {
  Payment,
  KYC,
  Agreement,
  Expedited,
  Accreditation,
  Returning,
  Notes,
} as const;

Chips.displayName = "Chips";