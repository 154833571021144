import { useMutation, useQuery } from "@tanstack/react-query";

import IssuerClient from "@equidefi/portals/clients/IssuerClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

export const useIssuerCreate = (options = {}) => {
  const api = useApi(IssuerClient);

  return useMutation((data) => api.createIssuer(data), options);
};

/**
 * @param {string | undefined} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuer = (id, options = {}) => {
  const api = useApi(IssuerClient);

  return useQuery(["issuers", id], () => api.getIssuer(id), {
    enabled: !!id,
    ...options,
  });
};

export const useIssuerUpdate = (issuerId, options = {}) => {
  const api = useApi(IssuerClient);

  return useMutation((data) => api.updateIssuer(issuerId, data), options);
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuerUsers = (id, options = {}) => {
  const api = useApi(IssuerClient);

  return useQuery({
    queryKey: ["issuers", id, "users"],
    queryFn: () => api.users(id),
    enabled: !!id,
    ...options,
  });
};

/**
 * @param {string} issuerId
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useRoleList = (issuerId, options = {}) => {
  const api = useApi(IssuerClient);

  return useQuery(["roles", issuerId], () => api.listRoles(issuerId), options);
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useRoleCreate = (options = {}) => {
  const api = useApi(IssuerClient);

  return useMutation(
    ({ issuerId, name }) => api.createRole(issuerId, name),
    options
  );
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useRoleUpdate = (options = {}) => {
  const api = useApi(IssuerClient);

  return useMutation(
    ({ issuerId, roleId, name }) => api.updateRole(issuerId, roleId, name),
    options
  );
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useRoleDelete = (options = {}) => {
  const api = useApi(IssuerClient);

  return useMutation(
    ({ issuerId, roleId }) => api.deleteRole(issuerId, roleId),
    options
  );
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useUserRoleUpdate = (options = {}) => {
  const api = useApi(IssuerClient);

  return useMutation(
    ({ issuerId, userId, data }) => api.updateUserRole(issuerId, userId, data),
    options
  );
};
