import { Container, TabList, Tabs, VStack } from "@chakra-ui/react";
import { Outlet, useLocation, useParams } from "react-router";

import { useOfferingContext } from "./OfferingContext";
import { OfferingHeaderCard } from "./components/OfferingHeaderCard";
import { Tab } from "@equidefi/ui";
import React, { useMemo } from "react";


export function OfferingNavigation(): React.JSX.Element {
  const location = useLocation();

  const defaultIndex = useMemo(() => {
    if (location.pathname.includes('details')) {
      return 1
    } else if (location.pathname.includes('closing')) {
      return 2
    } else if (location.pathname.includes('tranches')) {
      return 3
    } else {
      return 0
    }
  }, [location]);

  return (
    <Tabs mb={2} variant="equidefi" defaultIndex={defaultIndex}>
      <TabList>
        <Tab href={`./investments`}>Investments</Tab>
        <Tab href={`./details`}>Offering Details</Tab>
        <Tab href={`./closing`}>Closing</Tab>
        <Tab href={`./tranches`}>Closed Tranches</Tab>
      </TabList>
    </Tabs>
  )
}

export default function OfferingAreaContainer(): React.JSX.Element {

  const { offering: offeringData } = useOfferingContext();
  const { offering } = offeringData || {};

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch">
        <OfferingHeaderCard offering={offering} />
        <OfferingNavigation />
        <Outlet />
      </VStack>
    </Container>
  );
}
