import { TPaginationDTO } from "@/types/dto";
import { Flex, Text } from "@chakra-ui/react";
import { NumberFormatter } from "@equidefi/portals/helpers/format";
import { Pagination } from "@equidefi/ui";

type TPaginationRowProps = {
  name: string;
  pluralName?: string;
  pagination: TPaginationDTO;
  onPageChange: (page?: number | string) => void;
};

const PaginationRow = ({
  name,
  pluralName = `${name}s`,
  pagination,
  onPageChange = () => {},
}: TPaginationRowProps) => {
  return (
    <Flex w="full" justify="space-between" align="center" mb="2">
      {!pagination ? (
        "..."
      ) : (
        <Text color="gray" mb="0">
          {NumberFormatter.format(pagination.totalItems ?? 0)}{" "}
          {pagination.totalItems === 1 ? name : pluralName} found
          {pagination.totalItems > 0 && (
            <span>
              {" "}
              - Page {NumberFormatter.format(
                pagination.currentPage ?? 0
              )} of {NumberFormatter.format(pagination.totalPages ?? 0)}
            </span>
          )}
        </Text>
      )}
      <Pagination
        size="sm"
        currentPage={pagination.currentPage}
        totalPages={pagination.totalPages}
        onGoToPage={onPageChange}
      />
    </Flex>
  );
};

export default PaginationRow;
