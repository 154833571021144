import { Box, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";
import moment from "moment";

import { Icon } from "@equidefi/ui/icon";

import { InfoArea } from "../InfoArea";
import { EventBadge } from "./StatusBadge";

const EventHistoryItem = ({ event }) => {
  return (
    <Box
      py={4}
      borderBottom="1px"
      borderColor="gray.200"
      _last={{ border: "unset" }}
      key={event.id}
    >
      <Flex
        as="span"
        justify="space-between"
        align="center"
        gap="1"
        mb="1"
        textTransform="capitalize"
      >
        <EventBadge
          size="md"
          event={event?.workflow_event}
          action={event?.workflow_action}
        />
      </Flex>
      <Text _empty={{ display: "none" }} fontSize="sm" mb="1" color="gray.600">
        {event?.description}
      </Text>
      <Flex color="gray.400" fontSize="xs" direction="column">
        <Text as="span" textTransform="capitalize">
          {event?.display_name?.toLowerCase() ?? "EquiDeFi Admin"}
        </Text>

        <Text as="span">{moment(event.create_date).format("lll")}</Text>
      </Flex>
    </Box>
  );
};

export const InvestmentEventHistory = ({
  investment,
  canPoke = false,
  onPoke = () => {},
  ...rest
}) => {
  if (investment?.event_history?.length < 1) return null;
  return (
    <InfoArea
      title="Recent Activity"
      w="full"
      headerRightElement={
        canPoke && (
          <Tooltip placement="auto-start" label="Poke Investor" hasArrow>
            <IconButton
              colorScheme="gray"
              aria-label="Poke Investor"
              icon={<Icon.Mail size="1em" />}
              size="xs"
              onClick={onPoke}
            />
          </Tooltip>
        )
      }
      {...rest}
    >
      {investment?.event_history?.map((event) => (
        <EventHistoryItem key={event.id} event={event} />
      ))}
    </InfoArea>
  );
};
