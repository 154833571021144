/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from "react-router";

import { Button } from "@chakra-ui/react";

import Header from "@/layouts/header";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useIssuers } from "@equidefi/portals/hooks/useIssuers";
import FloatingSearch from "@/components/floating-search";
import PaginationRow from "@/components/PaginationRow"; 
import { isAdmin } from "@/helpers";
import { useQueryParams } from "@equidefi/portals/hooks/useQueryParam";
import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import Loader from "@/components/spinner";

const Issuers = (props) => {
  useDocumentTitle(["Issuers"]);

  const navigate = useNavigate();

  const { data: me } = useCurrentUser();

  const queryParams = useQueryParams({
    search: { type: "string" },
    page: { defaultValue: 1, type: "number" },
    perPage: { defaultValue: 25, type: "number" },
  });

  const { data, isLoading: spinner } = useIssuers({
    search: queryParams.get("search"),
    page: queryParams.get("page"),
    perPage: queryParams.get("perPage"),
  });

  const { issuers, pagination } = data ?? {};

  return (
    <>
      <Header name="Issuers">
        {isAdmin(me) && (
          <Button as={Link} to="/issuer/create">
            New Issuer
          </Button>
        )}
      </Header>

      <div className="container-fluid">
        <FloatingSearch
          placeholder="Search by name, description, type..."
          id="search-issuers"
          label="Search Issuers"
          onChange={(e) => queryParams.set({ search: e.target.value })}
        />

        <Loader show={spinner}>
          <PaginationRow
            name="issuer"
            pagination={pagination}
            onPageChange={(page) => queryParams.set({ page })}
          />
          <div className="card">
            <div className="table-responsive">
              <table className="table table-hover table-nowrap card-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {issuers?.map((issuer, index) => {
                    return (
                      <tr
                        key={issuer.id}
                        onClick={() =>
                          navigate(`/issuer/${issuer.id}/offerings`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td className="align-baseline">{issuer.name}</td>
                        <td className="text-capitalize align-baseline">
                          {issuer.cik ? (
                            <>
                              {issuer?.business_city}
                              {issuer?.business_city && issuer?.business_state
                                ? ", "
                                : ""}
                              {issuer?.business_state}
                            </>
                          ) : (
                            <>
                              {issuer?.address_city}
                              {issuer?.address_city && issuer?.address_state
                                ? ", "
                                : ""}
                              {issuer?.address_state}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <PaginationRow
            name="issuer"
            pagination={pagination}
            onPageChange={(page) => queryParams.set({ page })}
          />
        </Loader>
      </div>
    </>
  );
};

export default Issuers;
