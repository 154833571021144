import
  {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
  } from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Textarea } from "@equidefi/ui";

import { MESSAGE } from "../../../constants/forms";
import { useInvestmentCancelPayment } from "../../../hooks/investments";

const InitialValues = {
  notes: "",
};

const CancelSchema = Yup.object().shape({
  notes: Yup.string().required(MESSAGE.REQUIRED("notes")),
});

const ManualCancelModal = ({
  investmentId,
  paymentId,
  isOpen = false,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const toast = useToast();
  const cancelPayment = useInvestmentCancelPayment({
    onSuccess: () => {
      onHide();
      onSubmit();
      toast({
        status: "success",
        description: "Manual payment has been cancelled",
      });
    },
    onError: (e) => {
      console.error(e);
      toast({
        status: "error",
        description: "Something went wrong. Could not cancel payment.",
      });
    },
  });

  const handleCancelManualPayment = async (data) => {
    return cancelPayment.mutateAsync({
      investment_id: investmentId,
      payment_id: paymentId,
      notes: data.notes,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Payment</ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={InitialValues}
          validationSchema={CancelSchema}
          onSubmit={handleCancelManualPayment}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, errors, getFieldProps }) => (
            <form noValidate onSubmit={handleSubmit}>
              <ModalBody>
                <Textarea
                  {...getFieldProps("notes")}
                  isRequired
                  isInvalid={!!errors.notes}
                  error={errors.notes}
                  rows={3}
                  label="Notes"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup size="sm">
                  <Button
                    isDisabled={cancelPayment.isLoading}
                    colorScheme="gray"
                    variant="ghost"
                    onClick={onHide}
                  >
                    Discard
                  </Button>
                  <Button isLoading={cancelPayment.isLoading} type="submit">
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ManualCancelModal;
