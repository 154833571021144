import {
  Box,
  Card,
  CardBody,
  CardProps,
  Heading,
  HeadingProps,
  Image,
  ImageProps,
} from "@chakra-ui/react";
import React from "react";

type THeaderCardProps = {
  avatar?: string;
};

type THeaderAvatarProps = {
  url?: string;
  alt?: string;
  boxSize?: ImageProps["boxSize"];
};

export const HeaderAvatar: React.FC<THeaderAvatarProps> = ({
  url,
  alt,
  boxSize = "80px",
}) => {
  return (
    <Box boxSize={boxSize} bg="white" borderRadius="md">
      <Image
        src={url}
        {...(alt ? { alt } : {})}
        boxSize={boxSize}
        objectFit="contain"
      />
    </Box>
  );
};
export const HeaderTitle: React.FC<HeadingProps> = (props) => {
  return <Heading as="h1" fontSize="2xl" fontWeight="bold" {...props} />;
};

export const HeaderBreadcrumb = () => {};
export const HeaderBreadcrumbs = () => {};
export const HeaderActions = () => {};
export const HeaderAction = () => {};

export const HeaderCard: React.FC<CardProps> = ({ children, ...rest }) => {
  return (
    <Card {...rest}>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
