import { ButtonGroup, ButtonGroupProps } from "@chakra-ui/react";
import React from "react";

type TButtonActionGroupProps = ButtonGroupProps & {
  offset?: number;
};

export const ButtonActionGroup: React.FC<TButtonActionGroupProps> = ({
  offset = -2,
  ...props
}) => {
  return (
    <ButtonGroup
      variant="ghost"
      colorScheme="blue"
      spacing={4}
      __css={{
        "& > button:first-of-type, & > a:first-of-type": { ml: offset ?? -2 },
      }}
      {...props}
    />
  );
};
