import React from 'react';
import { ButtonGroup, ButtonGroupProps } from "@chakra-ui/react";
import { Link } from "react-router";
import { Chips, ChipSize, ChipVariant } from "./Chips";

export type StatusType = Exclude<ChipVariant, 'expedited'>;

export interface InvestmentProgressChipsProps extends Pick<ButtonGroupProps, 'className' | 'style'> {
  size?: ChipSize;
  linkBase?: string;
  paymentStatus?: StatusType;
  kycStatus?: StatusType;
  agreementStatus?: StatusType;
  accreditationStatus?: StatusType;
  expedited?: boolean;
  accreditation?: boolean;
}

export const InvestmentProgressChips: React.FC<InvestmentProgressChipsProps> = ({
  size = "sm",
  paymentStatus = "unknown",
  kycStatus = "unknown",
  agreementStatus = "unknown",
  accreditationStatus = "expedited",
  accreditation = true,
  expedited = false,
  linkBase,
  ...groupProps
}) => {
  const linkProps = (path: string) => ({
    ...(linkBase ? {
      as: Link,
      to: `${linkBase}/${path}`,
    } : {})
  });

  return (
    <ButtonGroup 
      spacing={2} 
      size={size} 
      verticalAlign="middle"
      {...groupProps}
    >
      <Chips.Payment
        {...linkProps("payments")}
        variant={paymentStatus}
        tooltipText="Payment"
        size={size}
      />

      <Chips.KYC
        {...linkProps("kyc")}
        variant={kycStatus}
        tooltipText="KYC / KYB"
        size={size}
      />

      <Chips.Agreement
        {...linkProps("agreements")}
        variant={agreementStatus}
        tooltipText="Agreement"
        size={size}
      />

      {expedited && (
        <Chips.Expedited
          {...linkProps("accreditation")}
          variant="expedited"
          tooltipText="Expedited"
          size={size}
        />
      )}

      {!expedited && accreditation && (
        <Chips.Accreditation
          {...linkProps("accreditation")}
          variant={accreditationStatus}
          tooltipText="Accreditation"
          size={size}
        />
      )}
    </ButtonGroup>
  );
};

InvestmentProgressChips.displayName = "InvestmentProgressChips";
