import { useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

import { extractErrorMessage } from "@equidefi/portals";
import { useUpdateStatusNext } from "@equidefi/portals/hooks/useInvestments";
import { ExpandableInput, Modal, Select } from "@equidefi/ui";

import { MESSAGE } from "@/constants/forms";
import { TInvestmentDTO } from "@/types/dto";

const ValidCombinations = [
  ["REGISTRATION", "PENDING"],
  ["SUBSCRIPTION", "PENDING"],
  ["PROFILE", "PENDING"],
  ["PAYMENT", "PENDING"],
  ["ADDRESS", "PENDING"],
  ["SIGNERS", "PENDING"],
  ["KYC", "PENDING"],
  ["AGREEMENT", "PENDING"],
  ["ACCREDITATION", "PENDING"],
  ["DILIGENCE", "REVIEW"],
  ["COUNTERSIGN", "APPROVED"],
  ["CLOSING", "COMPLETED"],
];

const validStatusNextOptions = ValidCombinations.map((row) => ({
  label: row.join(" "),
  value: row.join(" "),
}));

const StatusStepSchema = Yup.object().shape({
  stepstatus: Yup.string().required(MESSAGE.REQUIRED("step and status")),
  content: Yup.string().required(),
});

type TInvestmentStatusStepModalProps = {
  investment: TInvestmentDTO;
  isOpen?: boolean;
  onHide?: () => void;
  onSubmit?: () => void;
};

type TInvestmentStatusNextForm = {
  stepstatus: string;
  content: string;
};

const UpdateStatusStepModal: React.FC<TInvestmentStatusStepModalProps> = ({
  investment,
  isOpen = false,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const defaultValues = useMemo<TInvestmentStatusNextForm>(
    () => ({
      stepstatus: [investment?.status, investment?.next].join(" "),
      content: "",
    }),
    [investment?.status, investment?.next]
  );
  const toast = useToast();

  const updateInvestment = useUpdateStatusNext(investment?.id, {
    onSuccess: () => {
      onHide();
      onSubmit();
      toast({
        status: "success",
        description: "Investment status and worfklow step have been updated",
      });
    },
    onError: (e: Error) => {
      console.error(e);
      toast({
        status: "error",
        description: extractErrorMessage(e),
      });
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<TInvestmentStatusNextForm>({
    defaultValues,
    resolver: yupResolver(StatusStepSchema),
  });

  const handleReset = useCallback(
    (data?: TInvestmentStatusNextForm) => {
      reset({
        ...defaultValues,
        ...(data ?? {}),
      });
    },
    [reset]
  );

  const handleOnClose = useCallback(() => {
    handleReset();
    onHide();
  }, [onHide, handleReset]);

  const handleUpdate = useCallback(
    async (data: TInvestmentStatusNextForm) => {
      const [next, status] = data.stepstatus
        .split(" ")
        .map((v) => v.toUpperCase());

      await updateInvestment.mutateAsync({
        status,
        next,
        content: data.content,
      });

      handleReset({
        ...data,
        content: "",
      });
    },
    [updateInvestment, handleReset]
  );

  return (
    <Modal<TInvestmentStatusNextForm>
      title="Update Status and Step"
      isOpen={isOpen}
      onClose={handleOnClose}
      onSubmit={handleSubmit(handleUpdate)}
      size="sm"
      buttons={[
        {
          label: "Update",
          type: "submit",
          isLoading: updateInvestment.isLoading,
        },
        {
          label: "Cancel",
          action: handleOnClose,
          variant: "outline",
        },
      ]}
    >
      <Controller
        control={control}
        name="stepstatus"
        render={({ field: { name, onChange, value, ref } }) => (
          <Select
            name={name}
            label="Step and Status"
            isRequired
            isInvalid={!!errors.stepstatus}
            error={errors.stepstatus?.message}
            inputRef={ref}
            classNamePrefix="addl-class"
            options={validStatusNextOptions}
            value={validStatusNextOptions.find((c) => c.value === value)}
            onChange={(val: { value: string; label: string }) => {
              onChange(val.value);
            }}
            controlProps={{ mt: 1, mb: 2 }}
          />
        )}
      />

      <ExpandableInput
        label="Notes"
        isRequired
        isInvalid={!!errors.content}
        error={errors.content?.message}
        rows={3}
        value={watch("content")}
        {...register("content")}
      />
    </Modal>
  );
};

export default UpdateStatusStepModal;
