import type { UseQueryResult } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { Outlet, useParams } from "react-router";

import { useInvestment } from "@/hooks/investments";
import { TInvestmentDTO } from "@/types/dto";

type InvestmentContextType = { investmentId?: string } & UseQueryResult<
  TInvestmentDTO,
  unknown
>;

const InvestmentContext = createContext<InvestmentContextType>({});

export default function InvestmentContextProvider(): React.JSX.Element {
  const { investmentId } = useParams();
  const query = useInvestment(investmentId as string);

  return (
    <InvestmentContext.Provider value={{ ...query, investmentId }}>
      <Outlet />
    </InvestmentContext.Provider>
  );
}

export const useInvestmentContext = () => {
  const { data: investment, ...rest } = useContext(InvestmentContext);

  return { investment, ...rest };
};
